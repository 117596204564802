/* eslint-disable react/prop-types */
import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputMask from 'react-input-mask';
import { CurrencyInput } from 'react-currency-mask';
import { BaseModal } from '../../../../../../shared/components/modal/BaseModal';
import { useDependente } from '../../../../../../shared/hooks/dependentes/useDependente';
import { validationCreateDependente } from '../../../../validations/associados.schema';
import { type } from 'os';

type EditDepedenteModalProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  getDependentesByAssociado?: any;
  dependente: any;
  associado: any;
};
export const CreateDependenteModal: React.FC<EditDepedenteModalProps> = ({
  onClose,
  dependente,
  associado,
  getDependentesByAssociado,
  ...rest
}) => {
  const { loading: loadindDep, saveDependente } = useDependente();

  const factoryData = () => {
    return {
      nome: dependente?.nome,
      dataNascimento: dependente?.dataNascimento,
      grauParentesco: dependente?.grauParentesco,
    };
  };
  const {
    touched,
    errors,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<any>({
    onSubmit: async (values) => {
      await saveDependente(values);
      await getDependentesByAssociado(associado?.id);
      onClose();
      resetForm();
    },
    enableReinitialize: true,
    validationSchema: validationCreateDependente,
    initialValues: {
      ...factoryData(),
    },
  });
  return (
    <BaseModal loading={loadindDep} onClose={onClose} hasForm {...rest}>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Stack
            display={'flex'}
            alignItems="center"
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <TextField
              margin="dense"
              name="nome"
              label="NOME COMPLETO"
              type="text"
              size="small"
              fullWidth
              variant="outlined"
              value={values.nome}
              onChange={handleChange('nome')}
              onBlur={handleBlur('nome')}
              error={!!(errors?.nome && touched?.nome)}
            />
          </Stack>

          <Stack
            display={'flex'}
            alignItems="center"
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <TextField
              margin="dense"
              name="dataNascimento"
              type="date"
              size="small"
              label="DATA DE NASCIMENTO"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              value={values.dataNascimento}
              onChange={handleChange('dataNascimento')}
              onBlur={handleBlur('dataNascimento')}
              error={!!(errors?.dataNascimento && touched?.dataNascimento)}
            />

            <TextField
              margin="dense"
              name="grauParentesco"
              label="GRAU DE PARENTESCO"
              type="text"
              fullWidth
              size="small"
              variant="outlined"
              value={values.grauParentesco}
              onChange={handleChange('grauParentesco')}
              onBlur={handleBlur('grauParentesco')}
              error={!!(errors?.grauParentesco && touched?.grauParentesco)}
            />
          </Stack>
          <Grid container justifyContent="flex-end" mt={3}>
            <Grid item xs={3}>
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                variant="contained"
                loading={loadindDep}
                onClick={() => {
                  setFieldValue('idAssociado', associado.id);
                }}
                type="submit"
              >
                Adicionar Dependente
              </LoadingButton>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </BaseModal>
  );
};
