import React from 'react';
import { Alert, Button, Icon, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Toolbar } from '../../../../shared/components/toolbar/Toolbar';
import { useApplicationContext } from '../../../../shared/context/ApplicationContext';
import { Environment } from '../../../../shared/environment';
import { usePlanos } from '../../../../shared/hooks/planos/usePlanos';
import { LayoutBase } from '../../../../shared/layouts';
import { CreatePlanoModal } from './components/modal/Create/CreatePlano';
import { EditarPlanoModal } from './components/modal/Editar/EditarPlano';
import { useDebounce } from '../../../../shared/hooks/useDebounce';
import { RemovePlanoModal } from './components/modal/Remove/RemovePlano';

export const Planos: React.FC = () => {
  const { errorAlert, changeErrorAlert } = useApplicationContext();
  const [ plano, setPlano ] = React.useState<any>();
  const { planos, getPlanos, getPlanosSearch, removePlano, loading } = usePlanos();
  const [search, setSearch] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [idDelete, setIdDelete] = React.useState<number>(0);
  const { debounce } = useDebounce();

  React.useEffect(()=>{
    getPlanos();
  },[]);

  const onChangeSearch = React.useCallback((value: string) =>{
    setSearch(value);
    console.log(value);

    if(value === ''){
      getPlanos();
    }else{
      debounce(()=>{
        getPlanosSearch({param: value});
      });
    }

  },[search]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickEditOpen = (_plano: any) => {
    setPlano(_plano);
    setOpenEdit(true);
  };
  const handleClickRemoveOpen = (id: number) => {
    setIdDelete(id);
    setOpenDelete(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleRemoveClose = () => {
    setOpenDelete(false);
  };

  return (
    <LayoutBase
      title='Gerenciamento de Planos'
      fontSmall
      toolbar={(<Toolbar
        showField
        showButton
        value={search}
        onChange={onChangeSearch}
        handleAction={handleClickOpen}
      />)}
    >

      <TableContainer sx={{ minWidth: 400, width: 'auto', m: 1 }} component={Paper}>
        <Table size="small" aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>CNPJ</TableCell>
              <TableCell>NOME FANTASIA</TableCell>
              <TableCell>RAZÃO SOCIAL</TableCell>
              <TableCell>EMAIL</TableCell>
              <TableCell>VALOR</TableCell>
              <TableCell>AÇÕES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planos.map((row: any, index) => 
              <TableRow
                key={index}
                sx={
                  { '&:last-child td, &:last-child th': { border: 0 } }
                }
              >
                <TableCell component="th" scope="row">{row?.cnpj}</TableCell>
                <TableCell>{row?.nome}</TableCell>
                <TableCell>{row?.corporateName}</TableCell>
                <TableCell>{row?.email}</TableCell>
                <TableCell>{row?.valor}</TableCell>
                <TableCell colSpan={1}>
                  <Button
                    variant='outlined'
                    disableElevation
                    onClick={()=> handleClickEditOpen(row)}
                    sx={{
                      marginRight: 1
                    }}
                  >
                    <Icon>mode_edit</Icon>
                  </Button>
                  <Button
                    variant='outlined'
                    disableElevation
                    color='error'
                    onClick={()=>handleClickRemoveOpen(row?.id)}
                  >
                    <Icon>delete</Icon>
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {planos.length == 0 && !loading &&
            <caption>{Environment.LIST_EMPTY}</caption>
          }
        </Table>
      </TableContainer>

      <CreatePlanoModal
        open={open}
        getPlanos={getPlanos}
        onClose={handleClose}
        title="Novo Plano"
        description='Formulário de cadastro de Planos da ASSTRANS'
      />

      <EditarPlanoModal
        plano={plano}
        open={openEdit}
        getPlanos={getPlanos}
        onClose={handleEditClose}
        title="Atualizar Plano"
        description='Formulário de atualização de Planos da ASSTRANS'
      />

      <RemovePlanoModal
        id={idDelete}
        title='Remover Plano'
        ask='VC deseja mesmo?'
        openDelete={openDelete}
        handleDeleteClose={handleRemoveClose}
        deleteConvenio={removePlano}
        getPlanos={getPlanos}
      />

      {errorAlert && 
        <Snackbar  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={()=>changeErrorAlert(null)} open={errorAlert.error} autoHideDuration={3000}>
          <Alert onClose={()=>changeErrorAlert(null)} severity={errorAlert.type} sx={{ width: '100%' }}>
            {errorAlert?.message}
          </Alert>
        </Snackbar>
      }
    </LayoutBase>
  );
};
