import axios from 'axios';
import { Environment } from '../../../environment';
import { errorInterceptor, responseInterceptor } from './interceptors';

const Api = axios.create({
  baseURL: Environment.URL_BASE
});

Api.interceptors.request.use(
  async config => {
    if(localStorage.getItem('asstrans:token')){
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem('asstrans:token')}`,
        'Accept': 'application/json'
      };
    }

    return config;
  },
  error => {
    Promise.reject(error);
  });

Api.interceptors.response.use(
  response => responseInterceptor(response),
  error => errorInterceptor(error)
);
export { Api };