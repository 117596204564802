import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type RemovePlanoProps = {
  title: string,
  ask: string,
  id: number,
  openDelete: boolean,
  handleDeleteClose: any,
  deleteConvenio: any,
  getPlanos: any
}
export const RemovePlanoModal: React.FC<RemovePlanoProps> = ({
  id,
  title,
  openDelete,
  ask,
  handleDeleteClose,
  getPlanos,
  deleteConvenio }) => {
  return (
    <Dialog
      open={openDelete}
      onClose={handleDeleteClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {ask}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteClose}>NÃO</Button>
        <Button onClick={async ()=>{
          await deleteConvenio(id);
          getPlanos();
          handleDeleteClose();
        }}>
          SIM
        </Button>
      </DialogActions>
    </Dialog>
  );
};