import { Api } from '../axios-config';

const findAll = async (page: number, limit: number, params: string | null): Promise<any | Error> => {
  try {
    let relative = `/requisicoes/?${params}&page=${page}&limit=${limit}`;
    if (!params) {
      relative = `/requisicoes?page=${page}&limit=${limit}`;
    }
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao listar requisições.');
  }
};

const findParcelasByRequisicao = async (id: number): Promise<any | Error> => {
  try {
    const { data } = await Api.get(`/requisicoes/${id}/parcelas`);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao listar as parcelas.');
  }
};

const create = async (params: any): Promise<any | Error> => {
  try {
    const { data } = await Api.post('/requisicoes', params);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao cadastrar a requisição.');
  }
};

const remove = async (id: number): Promise<any | Error> => {
  try {
    await Api.delete(`/requisicoes/${id}`);
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao deletar a requisição.');
  }
};


const approved = async (params: any): Promise<void | Error> => {
  try {
    await Api.post('/requisicoes/aceitar', params);
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao aceitar a requisição.');
  }
};
const reproved = async (params: any): Promise<void | Error> => {
  try {
    await Api.post('/requisicoes/reprovar', params);
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao reprovar a requisição.');
  }
};

const quitarParcela = async (params: any): Promise<void | Error> => {
  try {
    await Api.post('/requisicoes/parcelas/baixa', params);
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao quitar a parcela.');
  }
};

export const RequisicoesService = {
  create,
  remove,
  findAll,
  findParcelasByRequisicao,
  approved,
  reproved,
  quitarParcela
};