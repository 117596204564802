import { Routes, Route, Navigate } from 'react-router-dom';
import { Dashboard } from '../pages';
import { ListConvenios } from '../pages/convenios/list/ListConvenios';
import { ListAssociados } from '../pages/associados/list/ListAssociados';
import { ListRequisicoes } from '../pages/requisicoes/list/ListRequisicoes';
import { ListRelatorioPage } from '../pages/relatorios/list';
import { ListPlanos } from '../pages/planos/list/ListPlanos';
import { Perfil } from '../pages/perfil/Perfil';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/pagina-inicial" element={<Dashboard />} />
      <Route path="/perfil" element={<Perfil />} />
      <Route path="/convenios" element={<ListConvenios />} />
      <Route path="/associados" element={<ListAssociados />} />
      <Route path="/planos" element={<ListPlanos />} />
      <Route path="/requisicoes" element={<ListRequisicoes />} />
      <Route path="/relatorios" element={<ListRelatorioPage />} />
      <Route path="*" element={<Navigate to={'/pagina-inicial'} />} />
    </Routes>
  );
}
