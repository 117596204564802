const months: any = {
  0: 'JAN',
  1: 'FEV',
  2: 'MAR',
  3: 'ABR',
  4: 'MAI',
  5: 'JUN',
  6: 'JUL',
  7: 'AGO',
  8: 'SET',
  9: 'OUT',
  10: 'NOV',
  11: 'DEZ'
};

export const getMonths = (index: number | null) => {
  const _month = index || 0;
  return months[_month];
};