/* eslint-disable react/prop-types */
import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputMask from 'react-input-mask';
import { CurrencyInput } from 'react-currency-mask';
import { useAssociado } from '../../../../../shared/hooks/associados/useAssociado';
import { BaseModal } from '../../../../../shared/components/modal/BaseModal';
import { validationCreateDependente } from '../../../validations/associados.schema';
import { useDependente } from '../../../../../shared/hooks/dependentes/useDependente';

type EditDepedenteModalProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  getDependentesByAssociado?: any;
  dependente: any;
  associado: any;
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const rows: any[] = [];
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && 
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      }
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const zones = [
  'NORTE',
  'SUL',
  'LESTE',
  'OESTE',
  'CENTRO_SUL',
  'DOT',
  'SEDE',
  'OUTROS',
];
export const EditDepedenteModal: React.FC<EditDepedenteModalProps> = ({
  onClose,
  dependente,
  associado,
  getDependentesByAssociado,
  ...rest
}) => {
  const { loading: loadindDep, updateDependente } = useDependente();

  const factoryData = () => {
    return {
      nome: dependente?.nome,
      dataNascimento: dependente?.dataNascimento,
      grauParentesco: dependente?.grauParentesco,
    };
  };
  const {
    touched,
    errors,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik<any>({
    onSubmit: async (values) => {
      console.log('values', values, dependente?.id);
      await updateDependente(dependente?.id, values);
      await getDependentesByAssociado(associado?.id);
      onClose();
    },
    enableReinitialize: true,
    validationSchema: validationCreateDependente,
    initialValues: {
      ...factoryData(),
    },
  });

  return (
    <BaseModal loading={loadindDep} onClose={onClose} hasForm {...rest}>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Stack
            display={'flex'}
            alignItems="center"
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <TextField
              margin="dense"
              name="nome"
              label="NOME COMPLETO"
              type="text"
              size="small"
              fullWidth
              variant="outlined"
              value={values.nome}
              onChange={handleChange('nome')}
              onBlur={handleBlur('nome')}
              error={!!(errors?.nome && touched?.nome)}
            />
          </Stack>

          <Stack
            display={'flex'}
            alignItems="center"
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <TextField
              margin="dense"
              name="dataNascimento"
              type="date"
              size="small"
              label="DATA DE NASCIMENTO"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              value={values.dataNascimento}
              onChange={handleChange('dataNascimento')}
              onBlur={handleBlur('dataNascimento')}
              error={!!(errors?.dataNascimento && touched?.dataNascimento)}
            />

            <TextField
              margin="dense"
              name="grauParentesco"
              label="GRAU DE PARENTESCO"
              type="text"
              fullWidth
              size="small"
              variant="outlined"
              value={values.grauParentesco}
              onChange={handleChange('grauParentesco')}
              onBlur={handleBlur('grauParentesco')}
              error={!!(errors?.grauParentesco && touched?.grauParentesco)}
            />
          </Stack>
          <DialogActions>
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Cancelar
            </Button>
            <LoadingButton
              variant="contained"
              loading={loadindDep}
              type="submit"
            >
              atualizar
            </LoadingButton>
          </DialogActions>
        </CardContent>
      </form>
    </BaseModal>
  );
};
