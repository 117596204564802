/* eslint-disable react/prop-types */
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { BaseModal } from '../../../../../shared/components/modal/BaseModal';
import { TabPanel } from '../../../../../shared/components/tabs/TabPanel';
import { useAssociado } from '../../../../../shared/hooks/associados/useAssociado';
import { useDependente } from '../../../../../shared/hooks/dependentes/useDependente';
import InputMask from 'react-input-mask';
import { CurrencyInput } from 'react-currency-mask';

type CreateAssociadoProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  getAssociados: any;
};

const rows: any[] = [];
export const CreateAssociadoModal: React.FC<CreateAssociadoProps> = ({
  onClose,
  getAssociados,
  ...rest
}) => {
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpenModal] = React.useState(false);
  const {
    loading,
    props,
    idAssociado,
    setIdAssociado,
    getAssociadoById,
    associado,
    dependentes,
    getDependentesByAssociado,
  } = useAssociado({ getAssociados }, setValue);
  const { loading: loadindDep, props: propsDep } = useDependente();

  React.useEffect(() => {
    if (value === 1 && idAssociado !== 0) {
      getAssociadoById(idAssociado);
      getDependentesByAssociado(idAssociado);
    }
  }, [value, associado]);

  const changeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <BaseModal
      loading={loading}
      onClose={onClose}
      onSubmit={props.handleSubmit}
      hasForm
      {...rest}
    >
      <Tabs
        value={value}
        onChange={changeTabs}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
        variant="fullWidth"
      >
        <Tab label="Dados" disabled={idAssociado !== 0} {...a11yProps(0)} />
        <Tab
          label="Dependentes"
          disabled={idAssociado == 0}
          {...a11yProps(1)}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Box>
          <form onSubmit={props.handleSubmit}>
            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <TextField
                margin="dense"
                name="name"
                label="NOME COMPLETO"
                type="text"
                fullWidth
                variant="outlined"
                value={props.values?.name}
                onChange={props.handleChange('name')}
                onBlur={props.handleBlur('name')}
                error={!!(props.errors?.name && props.touched?.name)}
              />
            </Stack>

            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <TextField
                margin="dense"
                name="email"
                label="EMAIL"
                type="text"
                fullWidth
                variant="outlined"
                value={props.values.email}
                onChange={props.handleChange('email')}
                onBlur={props.handleBlur('email')}
                error={!!(props.errors?.email && props.touched?.email)}
              />
            </Stack>

            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <TextField
                margin="dense"
                name="zoneCity"
                label="ZONA"
                select
                type="text"
                fullWidth
                variant="outlined"
                placeholder="ZONA"
                value={props.values.zoneCity}
                onChange={props.handleChange('zoneCity')}
                onBlur={props.handleBlur('zoneCity')}
                error={!!(props.errors?.zoneCity && props.touched?.zoneCity)}
              >
                <MenuItem value={'NORTE'}>NORTE</MenuItem>
                <MenuItem value={'SUL'}>SUL</MenuItem>
                <MenuItem value={'LESTE'}>LESTE</MenuItem>
                <MenuItem value={'OESTE'}>OESTE</MenuItem>
                <MenuItem value={'CENTRO_SUL'}>CENTRO-SUL</MenuItem>
                <MenuItem value={'DOT'}>DOT</MenuItem>
                <MenuItem value={'SEDE'}>SEDE</MenuItem>
                <MenuItem value={'OUTROS'}>OUTROS</MenuItem>
              </TextField>
            </Stack>

            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <TextField
                margin="dense"
                name="matricula"
                label="MATRÍCULA"
                type="text"
                fullWidth
                variant="outlined"
                value={props.values.matricula}
                onChange={props.handleChange('matricula')}
                onBlur={props.handleBlur('matricula')}
                error={!!(props.errors?.matricula && props.touched?.matricula)}
              />

              <TextField
                margin="dense"
                name="setor"
                label="SETOR"
                type="text"
                fullWidth
                variant="outlined"
                value={props.values.setor}
                onChange={props.handleChange('setor')}
                onBlur={props.handleBlur('setor')}
                error={!!(props.errors?.setor && props.touched?.setor)}
              />
            </Stack>
            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <InputMask
                value={props.values?.rg}
                onBlur={() => props.handleBlur('rg')}
                onChange={(value) => {
                  props.setFieldValue(
                    'rg',
                    value.target.value.replace(/[^0-9]+/g, '')
                  );
                }}
                mask="9999999-9"
              >
                <TextField
                  margin="dense"
                  name="rg"
                  label="RG"
                  type="text"
                  fullWidth
                  variant="outlined"
                  // value={props.values.rg}
                  // onChange={props.handleChange('rg')}
                  // onBlur={props.handleBlur('rg')}
                  error={!!(props.errors?.rg && props.touched?.rg)}
                />
              </InputMask>

              <InputMask
                value={props.values?.cpf}
                onBlur={() => props.handleBlur('cpf')}
                onChange={(value) => {
                  props.setFieldValue(
                    'cpf',
                    value.target.value.replace(/[^0-9]+/g, '')
                  );
                }}
                mask="999.999.999-99"
              >
                <TextField
                  margin="dense"
                  name="cpf"
                  label="CPF"
                  type="text"
                  fullWidth
                  variant="outlined"
                  // value={props.values.cpf}
                  // onChange={props.handleChange('cpf')}
                  // onBlur={props.handleBlur('cpf')}
                  error={!!(props.errors?.cpf && props.touched?.cpf)}
                />
              </InputMask>
            </Stack>
            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <InputMask
                value={props.values?.celular}
                onBlur={() => props.handleBlur('celular')}
                onChange={(value) => {
                  props.setFieldValue(
                    'celular',
                    value.target.value.replace(/[^0-9]+/g, '')
                  );
                }}
                mask="(99)99999-9999"
              >
                <TextField
                  margin="dense"
                  name="celular"
                  label="CELULAR"
                  type="text"
                  fullWidth
                  variant="outlined"
                  // value={props.values.celular}
                  // onChange={props.handleChange('celular')}
                  // onBlur={props.handleBlur('celular')}
                  error={!!(props.errors?.celular && props.touched?.celular)}
                />
              </InputMask>

              <CurrencyInput
                InputElement={
                  <TextField
                    margin="dense"
                    name="salarioBase"
                    label="SALÁRIO BASE"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={props.values.salarioBase}
                    onChange={props.handleChange('salarioBase')}
                    onBlur={props.handleBlur('salarioBase')}
                    error={
                      !!(
                        props.errors?.salarioBase && props.touched?.salarioBase
                      )
                    }
                  />
                }
                onChangeValue={(event, originalValue, maskedValue) => {
                  console.log(event, originalValue, maskedValue);
                  props.setFieldValue('salarioBase', originalValue);
                }}
              />
            </Stack>
            <DialogActions>
              <Button onClick={onClose}>Cancelar</Button>
              <LoadingButton
                variant="contained"
                loading={loading}
                type="submit"
              >
                Salvar
              </LoadingButton>
            </DialogActions>
          </form>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box sx={{ height: 410 }}>
          <Card
            sx={{
              mb: 1,
            }}
          >
            <form onSubmit={propsDep.handleSubmit}>
              <CardContent>
                <Stack
                  display={'flex'}
                  alignItems="center"
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                  <TextField
                    margin="dense"
                    name="nome"
                    label="NOME COMPLETO"
                    type="text"
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={propsDep.values.nome}
                    onChange={propsDep.handleChange('nome')}
                    onBlur={propsDep.handleBlur('nome')}
                    error={!!(propsDep.errors?.nome && propsDep.touched?.nome)}
                  />
                </Stack>

                <Stack
                  display={'flex'}
                  alignItems="center"
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                  <TextField
                    margin="dense"
                    name="dataNascimento"
                    type="date"
                    size="small"
                    label="DATA DE NASCIMENTO"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="outlined"
                    value={propsDep.values.dataNascimento}
                    onChange={propsDep.handleChange('dataNascimento')}
                    onBlur={propsDep.handleBlur('dataNascimento')}
                    error={
                      !!(
                        propsDep.errors?.dataNascimento &&
                        propsDep.touched?.dataNascimento
                      )
                    }
                  />

                  <TextField
                    margin="dense"
                    name="grauParentesco"
                    label="GRAU DE PARENTESCO"
                    type="text"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={propsDep.values.grauParentesco}
                    onChange={propsDep.handleChange('grauParentesco')}
                    onBlur={propsDep.handleBlur('grauParentesco')}
                    error={
                      !!(
                        propsDep.errors?.grauParentesco &&
                        propsDep.touched?.grauParentesco
                      )
                    }
                  />
                </Stack>
                <Button
                  onClick={() => {
                    setIdAssociado(0);
                    setValue(0);
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  variant="contained"
                  loading={loadindDep}
                  onClick={() => {
                    propsDep.setFieldValue('idAssociado', idAssociado);
                    setValue(1);
                  }}
                  type="submit"
                >
                  Adicionar Dependente
                </LoadingButton>
              </CardContent>
            </form>
          </Card>
          <TableContainer style={{ marginBottom: 20 }} component={Paper}>
            <Table sx={{ mb: 2 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell align="right">Idade</TableCell>
                  <TableCell align="right">Parentesco</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dependentes?.map((row: any) => 
                  <TableRow
                    key={row.nome}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.nome}
                    </TableCell>
                    <TableCell align="right">
                      {new Intl.DateTimeFormat('pt-BR').format(
                        row.dataNascimento
                      )}
                    </TableCell>
                    <TableCell align="right">{row.grauParentesco}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <DialogActions>
          <LoadingButton
            variant="contained"
            onClick={() => {
              setIdAssociado(0);
              setValue(0);
              props.resetForm();
              onClose();
            }}
          >
            Finalizar
          </LoadingButton>
        </DialogActions>
      </TabPanel>
    </BaseModal>
  );
};
