import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Box, Card, CardContent, Snackbar, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useApplicationContext } from '../../../../shared/context/ApplicationContext';
import { useAssociados } from '../../../../shared/hooks/associados/useAssociados';
import { usePlanosAssociados } from '../../../../shared/hooks/planos-associados/usePlanosAssociados';
import { usePlanos } from '../../../../shared/hooks/planos/usePlanos';
import { useDebounce } from '../../../../shared/hooks/useDebounce';
import { ConfirmationModal } from '../planos/components/modal/Confirmation/ConfirmationModal';

export const PlanoAssociado: React.FC = () => {

  const { errorAlert, changeErrorAlert } = useApplicationContext();
  const [searchAssociado, setSearchAssociado] = React.useState('');
  const [searchPlanos, setSearchPlanos] = React.useState('');
  const [verify, setVerify] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openConfirmationRemove, setOpenConfirmationRemove] = React.useState(false);
  const [openConfirmationDependenteRemove, setOpenConfirmationDependenteRemove] = React.useState(false);
  const [openConfirmationDependente, setOpenConfirmationDependente] = React.useState(false);
  const [verifyDependente, setVerifyDependente] = React.useState(false);
  const { associados, getAssociados, getDependentesByAssociado, dependentes } = useAssociados();
  const { planos, getPlanosSearch  } = usePlanos();
  const { planoAssociado,
    planoAssociadoDependente,
    save,
    saveDependente,
    loading: loadingPlanosAssociados,
    verifyPlanoAssociado,
    verifyPlanoAssociadoDependente,
    remove,
    removeDependente
  } = usePlanosAssociados();
  const [ associado, setAssociado ] = React.useState({} as any);
  const [ plano, setPlano ] = React.useState({} as any);
  const [ dependente, setDependente ] = React.useState({} as any);
  const { debounce } = useDebounce();
  const theme = useTheme();


  const onChangeSearchAssociados = React.useCallback((value: string) =>{
    setSearchAssociado(value);
    debounce(()=>{
      getAssociados({searchParam: value});
    });
  },[searchAssociado]);

  const onChangeSearchPlanos = React.useCallback((value: string) =>{
    setSearchPlanos(value);
    debounce(()=>{
      getPlanosSearch({param: value});
    });
  },[searchPlanos]);

  return (
    <Box>

      <Typography  sx={{ m: 5}}>
        <b>Gerenciamento de Planos Associados e Dependentes</b>
      </Typography>
      <Card sx={{
        mb: 1
      }}>

        <Typography  sx={{ m: 5}}>
          <b>Área Associado</b>
        </Typography>

        <Stack
          sx={{ m: 5}}
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'column' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >

          <Typography>
            <b>Adicionar Associado</b>
          </Typography>

          <Autocomplete
            sx={{
              mb: '15px',

            }}
            onChange={(_, value) => {
              setAssociado(value);
              setVerify(false);
            }}
            fullWidth
            id="free-solo-2-demo"
            loading
            loadingText="Aguardando associados"
            noOptionsText="Sem dados"
            disableClearable
            getOptionLabel={(option)=> {
              return `${option.name} - ${option.matricula}` ;
            }}
            options={associados.map((option: any) => {
              return option;
            })}
            renderInput={(params) =>
              <TextField
                {...params}
                label="ASSOCIADO"
                onChange={e => onChangeSearchAssociados(e.currentTarget.value)}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            }
          />

          <Autocomplete
            sx={{
              mb: '15px'
            }}
            fullWidth
            id="free-solo-2-demo"
            disableClearable
            onChange={(_, value) => {
              setPlano(value);
              setVerify(false);
            }}
            getOptionLabel={(option)=> {
              return option.nome;
            }}
            loading
            loadingText="Carregando Planos"
            noOptionsText="Sem dados"
            options={planos.map((option: any) => {
              return option;
            })}
            renderInput={(params) =>
              <TextField
                {...params}
                label="PLANO"
                onChange={e => onChangeSearchPlanos(e.currentTarget.value)}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            }
          />

          {associado.id && plano.id &&
            <LoadingButton
              variant='contained'
              loading={loadingPlanosAssociados}
              type='button'
              onClick={() => {
                verifyPlanoAssociado(associado.id, plano.id);
                getDependentesByAssociado(associado.id);
                setVerify(true);
              }}
            >
              Verificar status do plano
            </LoadingButton>
          }

          <Typography>
            <b>Detalhes da Integração</b>
          </Typography>

          {verify &&
            <Box  minWidth={'50%'}>
              <Card  sx={{
                mb: 1
              }}>
                <CardContent sx={{textAlign: 'left'}}>
                  <Typography color={theme.palette.secondary.contrastText} component="div">
                    <b>Associado:</b> {associado.name}
                  </Typography>
                  <Typography color={theme.palette.secondary.contrastText} component="div">
                    <b>Matrícula:</b> {associado.matricula}
                  </Typography>
                  <Typography color={theme.palette.secondary.contrastText} component="div">
                    <b>Email:</b> {associado.email}
                  </Typography>
                  <Typography color={theme.palette.secondary.contrastText} component="div">

                  </Typography>
                  <Typography color={theme.palette.secondary.contrastText} component="div">
                    <b>Plano:</b> {plano.nome}
                  </Typography>
                  <Typography color={theme.palette.secondary.contrastText} component="div">
                    <b>Desconto:</b> {plano.valor}
                  </Typography>
                  <Box sx={{textAlign: 'center', mt: 2}}>
                    {planoAssociado?.id ?
                      <LoadingButton
                        variant='outlined'
                        color='error'
                        loading={loadingPlanosAssociados}
                        type='button'
                        onClick={() => {
                          setOpenConfirmationRemove(true);
                        }}
                      >
                      Remover do Plano
                      </LoadingButton>
                      :
                      <LoadingButton
                        variant='outlined'
                        color="success"
                        loading={loadingPlanosAssociados}
                        type='button'
                        onClick={() => {
                          setOpenConfirmation(true);

                        }}
                      >
                      Adicionar ao Plano
                      </LoadingButton>
                    }

                  </Box>
                </CardContent>
              </Card>
            </Box>
          }

        </Stack>
      </Card>


      <Card sx={{
        mb: 1
      }}>

        <Typography  sx={{ m: 5}}>
          <b>Área Dependentes</b>
        </Typography>

        { planoAssociado?.id ?
          <>
            <Stack
              sx={{ m: 5}}
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'column' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >

              <Typography>
                <b>Adicionar Dependentes</b>
              </Typography>

              <Autocomplete
                sx={{
                  mb: '15px'
                }}
                fullWidth
                id="free-solo-2-demo"
                disableClearable
                onChange={(_, value) => {
                  setDependente(value);
                }}
                getOptionLabel={(option: any)=> {
                  console.log('getOptionLabel');
                  //setIdAssociado(option.id);
                  //setFieldValue('associado',option.id);
                  return option.nome;
                }}
                options={dependentes?.map((option: any) => {
                  return option;
                })}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="DEPENDENTES"
                    onChange={e => onChangeSearchAssociados(e.currentTarget.value)}
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                }
              />

              {dependente?.id &&
                <LoadingButton
                  variant='contained'
                  loading={loadingPlanosAssociados}
                  type='button'
                  onClick={async () => {
                    await verifyPlanoAssociadoDependente(planoAssociado.id, dependente.id);
                    setVerifyDependente(true);
                  }}
                >
              Verificar Status do Plano
                </LoadingButton>
              }

              <Typography>
                <b>Detalhes da Integração</b>
              </Typography>

              {verifyDependente && dependente?.id &&
                <Box  minWidth={'50%'}>
                  <Card  sx={{
                    mb: 1,
                  }}>
                    <CardContent sx={{textAlign: 'left'}}>
                      <Typography color={theme.palette.secondary.contrastText} component="div">
                        <b>Titular:</b> {associado.name}
                      </Typography>
                      <Typography color={theme.palette.secondary.contrastText} component="div">
                        <b>Matrícula:</b> {associado.matricula}
                      </Typography>
                      <Typography color={theme.palette.secondary.contrastText} component="div">
                        <b>Email:</b> {associado.email}
                      </Typography>
                      <Typography color={theme.palette.secondary.contrastText} component="div">

                        <Typography color={theme.palette.secondary.contrastText} component="div">
                          <b>Dependente:</b> {dependente?.nome}
                        </Typography>
                        <Typography color={theme.palette.secondary.contrastText} component="div">
                          <b>Parentesco:</b> {dependente?.grauParentesco}
                        </Typography>
                        <Typography color={theme.palette.secondary.contrastText} component="div">
                          <b>Idade:</b> {dependente?.idade}
                        </Typography>

                      </Typography>
                      <Typography color={theme.palette.secondary.contrastText} component="div">
                        <b>Plano:</b> {plano.nome}
                      </Typography>
                      <Typography color={theme.palette.secondary.contrastText} component="div">
                        <b>Desconto:</b> {plano.valor}
                      </Typography>
                      <Box sx={{textAlign: 'center', mt: 2}}>
                        {planoAssociadoDependente?.id ?
                          <LoadingButton
                            variant='outlined'
                            color='error'
                            loading={loadingPlanosAssociados}
                            type='button'
                            onClick={() => {
                              setOpenConfirmationDependenteRemove(true);
                            }}
                          >
                      Remover do Plano
                          </LoadingButton>
                          :
                          <LoadingButton
                            variant='outlined'
                            color="success"
                            loading={loadingPlanosAssociados}
                            type='button'
                            onClick={() => {
                              setOpenConfirmationDependente(true);
                            }}
                          >
                      Adicionar ao Plano
                          </LoadingButton>
                        }

                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              }
            </Stack>
          </>
          :
          <>
            <Typography  sx={{ m: 5}}>
              Sem dependentes a adicionar
            </Typography>
          </>
        }
      </Card>

      <ConfirmationModal
        title='Adesão ao plano'
        ask='Você deseja realmente adicionar o associado ao plano?'
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        onHandler={async () => {
          await save({
            idAssociado: associado.id,
            idPlano: plano.id
          });
        }}
      />

      <ConfirmationModal
        title='Adesão de dependente ao plano'
        ask='Você deseja realmente adicionar o dependente ao plano?'
        open={openConfirmationDependente}
        handleClose={() => setOpenConfirmationDependente(false)}
        onHandler={async () => {
          await saveDependente({
            idPlanoAssociado: planoAssociado.id,
            idDependente: dependente.id
          });
        }}
      />

      <ConfirmationModal
        title='Remoção do plano'
        ask='Você deseja realmente remover o associado do plano?'
        open={openConfirmationRemove}
        handleClose={() => setOpenConfirmationRemove(false)}
        onHandler={async () => {
          remove(planoAssociado?.id);
          setDependente(null);
          setVerifyDependente(false);
        }}
      />

      <ConfirmationModal
        title='Remoção Dependente do plano'
        ask='Você deseja realmente remover o dependente do plano?'
        open={openConfirmationDependenteRemove}
        handleClose={() => setOpenConfirmationDependenteRemove(false)}
        onHandler={async () => {
          await removeDependente(planoAssociadoDependente.id);
        }}
      />

      {errorAlert &&
        <Snackbar  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={()=>changeErrorAlert(null)} open={errorAlert.error} autoHideDuration={3000}>
          <Alert variant='filled' onClose={()=>changeErrorAlert(null)} severity={errorAlert.type} sx={{ width: '100%' }}>
            {errorAlert?.message}
          </Alert>
        </Snackbar>
      }
    </Box>


  );
};
