import React from 'react';
import { useApplicationContext } from '../../context/ApplicationContext';
import { PlanosService } from '../../services/api/planos/PlanosService';

type UsePlanosProps = {
  page?: number,
  limit?: number,
  param?: string,
}
export function usePlanos() {
  const [planos, setPlanos] = React.useState([]);
  const [ totalElements, setTotalElements] = React.useState(0);
  const [ totalPage, setTotalPage] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const { changeErrorAlert } = useApplicationContext();

  const savePlano = async (data: any) => {
    try {
      setLoading(true);
      const result = await  PlanosService.save(data);
      changeErrorAlert(
        {
          error: true,
          message: 'Cadastro efetuado com sucesso!',
          type: 'success'
        });
    } catch (err: any) {
      console.log(err);
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };
  const updatePlano = async (id: number,data: any) => {
    try {
      setLoading(true);
      const result = await PlanosService.update(id,data);
      changeErrorAlert(
        {
          error: true,
          message: 'Atualização efetuada com sucesso!',
          type: 'success'
        });
    } catch (err: any) {
      console.log(err);
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };
  const removePlano = async (id: number) => {
    try {
      setLoading(true);
      await PlanosService.remove(id);
      changeErrorAlert(
        {
          error: true,
          message: 'Remoção efetuada com sucesso!',
          type: 'success'
        });
    } catch (err: any) {
      console.log(err);
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };
  const getPlanos = async () => {
    try {
      setLoading(true);
      const data = await  PlanosService.findAll();
      setTotalElements(data.totalElements);
      setTotalPage(data.totalPages);
      setPlanos(data.content);
    } catch (err: any) {
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };
  const getPlanosSearch = async (
    { page = 0,
      limit = 10,
      param=''}: UsePlanosProps) => {
    try {
      setLoading(true);
      const data = await  PlanosService.findAllSearch(page,limit,param);
      setTotalElements(data.totalElements);
      setTotalPage(data.totalPages);
      setPlanos(data.content);
    } catch (err: any) {
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };
  return {
    savePlano,
    updatePlano,
    removePlano,
    getPlanosSearch,
    getPlanos,
    planos,
    loading,
    totalElements,
    totalPage
  };
}