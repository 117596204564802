import { Alert, Autocomplete, Box, DialogActions, FormControl, InputLabel, MenuItem, Select, Snackbar, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { BaseModal } from '../../../../../shared/components/modal/BaseModal';
import { useApplicationContext } from '../../../../../shared/context/ApplicationContext';
import { useAssociados } from '../../../../../shared/hooks/associados/useAssociados';
import { useConvenios } from '../../../../../shared/hooks/convenios/useConvenios';
import { useRequisicoes } from '../../../../../shared/hooks/requisicoes/useRequisicoes';
import { useDebounce } from '../../../../../shared/hooks/useDebounce';
import { AssociadoService } from '../../../../../shared/services/api/associados/AssociadoService';
import { RequisicoesService } from '../../../../../shared/services/api/requisicoes/RequisicoesService';
import { validationCreateRequisicao } from '../../../validations/requisicoes.schema';
import { CurrencyInput } from 'react-currency-mask';
import { LoadingButton } from '@mui/lab';
import { log } from 'console';

type CreateAssociadoProps = {
  open: boolean,
  onClose: () => void,
  title: string,
  description?: string,
  getRequisicoes: any
}

export const CreateRequisicaoModal: React.FC<CreateAssociadoProps> = ({ onClose, getRequisicoes,...rest}) => {

  const [searchConvenio, setSearchConvenio] = React.useState('');
  const [searchAssociado, setSearchAssociado] = React.useState('');
  const [idAssociado, setIdAssociado] = React.useState('');
  const [idConvenio, setIdConvenio] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { convenios, getConvenios } = useConvenios();
  const { associados, getAssociados } = useAssociados();
  const [errorC, setErrorC] = React.useState<any>(null);
  const { changeErrorAlert } = useApplicationContext();
  const { debounce } = useDebounce();

  const factoryData = () => {
    return {
      total: '',
      parcela: ''
    };
  };

  const onChangeSearchConvenios = React.useCallback((value: string) =>{
    setSearchConvenio(value);
    console.log('on',value);
    debounce(()=>{
      getConvenios({searchParam: value});
    });
  },[searchConvenio]);

  const onChangeSearchAssociados = React.useCallback((value: string) =>{
    setSearchAssociado(value);
    console.log('on',value);
    debounce(()=>{
      getAssociados({searchParam: value});
    });
  },[searchAssociado]);

  const factoryParcelas = () => {
    return [
      { key: 1, name: '1x Parcela'},
      { key: 2, name: '2x Parcelas'},
      { key: 3, name: '3x Parcelas'},
      { key: 4, name: '4x Parcelas'},
      { key: 5, name: '5x Parcelas'},
      { key: 6, name: '6x Parcelas'},
      { key: 7, name: '7x Parcelas'},
      { key: 8, name: '8x Parcelas'},
      { key: 9, name: '9x Parcelas'},
      { key: 10, name: '10x Parcelas'},
      { key: 11, name: '11x Parcelas'},
      { key: 12, name: '12x Parcelas'},
    ];
  };

  const {
    touched,
    errors,
    resetForm,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik<any>({
    onSubmit: async (values) =>{
      console.log('VALUES',values);
      setLoading(true);
      try{
        await RequisicoesService.create({
          associadoId: idAssociado,
          convenioId: idConvenio,
          quantidadeParcelas: values.parcela,
          valorTotal: values.total
        });
        changeErrorAlert(
          {
            error: true,
            message: 'Cadastro efetuado com sucesso!',
            type: 'success'
          });

        getRequisicoes({});
        resetForm();
        onClose();

      }catch(error: any){
        console.log('Error', error);
        changeErrorAlert(
          {
            error: true,
            message: error.message,
            type: 'error'
          });
      }finally{
        setLoading(false);
      }
    },
    validationSchema: validationCreateRequisicao,
    initialValues: {
      ...factoryData()
    }
  });

  return (
    <BaseModal
      loading={loading}
      onClose={onClose}
      onSubmit={handleSubmit}
      hasForm
      {...rest}
    >

      <form onSubmit={handleSubmit}>
        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Autocomplete
            sx={{
              mb: '15px'
            }}
            fullWidth
            id="free-solo-2-demo"
            disableClearable
            onChange={(_, value: any) => {
              setIdAssociado(value.id);
            }}
            getOptionLabel={(option)=> {
            //setFieldValue('associado',option.id);
              return option.name;
            }}
            options={associados.map((option: any) => {
              return option;
            })}
            loadingText="Aguardando associados"
            noOptionsText="Sem dados"
            renderInput={(params) => 
              <TextField
                {...params}
                label="ASSOCIADO"
                onChange={e => onChangeSearchAssociados(e.currentTarget.value)}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            }
          />
        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Autocomplete
            sx={{
              mb: '15px'
            }}
            fullWidth
            id="free-solo-2-demo"
            disableClearable
            loadingText="Aguardando convênios"
            noOptionsText="Sem dados"
            onChange={(_, value: any) => {
              console.log(value);
              setIdConvenio(value.id);
            }}
            getOptionLabel={(option)=> {
              return option.fantasyName;
            }}
            options={convenios.map((option: any) => {
              return option;
            })}
            renderInput={(params) =>{
              return (
                <TextField
                  {...params}
                  label="CONVÊNIO"
                  onChange={e => onChangeSearchConvenios(e.currentTarget.value)}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              );}}
          />
        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}>
        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >

          <CurrencyInput
            InputElement={
              <TextField
                margin="dense"
                name="matrícula"
                label="TOTAL COMPRA"
                type="text"
                fullWidth
                variant="outlined"
                value={values.total}
                onChange={handleChange('total')}
                onBlur={handleBlur('total')}
                error={!!(errors?.total && touched?.total)}
              />
            }
            onChangeValue={(event, originalValue, maskedValue) => {
              console.log(event, originalValue, maskedValue);
              setFieldValue('total', originalValue);
            }}
          />

          <TextField
            margin="dense"
            name="parcela"
            label="PARCELAS"
            select
            type="number"
            fullWidth
            variant="outlined"
            value={values.parcela}
            onChange={handleChange('parcela')}
            onBlur={handleBlur('parcela')}
            error={!!(errors?.parcela && touched?.parcela)}
          >
            {factoryParcelas().map(({key, name}) => 
              <MenuItem key={key} value={key}>{name}</MenuItem>
            )};
          </TextField>

        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <TextField
            margin="dense"
            name="email"
            label="AUTORIZAÇÃO DE COMPRAS"
            type="text"
            fullWidth
            variant="outlined"
            disabled
          />

        </Stack>

        <DialogActions>
          <LoadingButton
            variant='contained'
            loading={loading}
            type='submit'>Adicionar
          </LoadingButton>
        </DialogActions>

        {errorC && 
          <Snackbar   anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={()=>setErrorC(null)} open={errorC} autoHideDuration={3000}>
            <Alert onClose={()=>setErrorC(null)} severity={errorC.type} sx={{ width: '100%' }}>
              {errorC?.message}
            </Alert>
          </Snackbar>
        }
      </form>
    </BaseModal>
  );
};
