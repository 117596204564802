import { BrowserRouter } from 'react-router-dom';
import { LoginPage } from './pages/login/Login';
import { AppRoutes } from './routes';
import { Menu } from './shared/components';
import { DrawerProvider, ThemeProvider } from './shared/context';
import { ApplicationProvider } from './shared/context/ApplicationContext';
import { AuthProvider } from './shared/context/AuthContext';

export function App() {
  return (
    <AuthProvider>
      <ApplicationProvider>
        <ThemeProvider>
          <LoginPage>
            <DrawerProvider>
              <BrowserRouter>
                <Menu>
                  <AppRoutes />
                </Menu>
              </BrowserRouter>
            </DrawerProvider>
          </LoginPage>
        </ThemeProvider>
      </ApplicationProvider>
    </AuthProvider>
  );
}

