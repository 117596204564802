/* eslint-disable react/prop-types */
import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { BaseModal } from '../../../../../shared/components/modal/BaseModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputMask from 'react-input-mask';
import { CurrencyInput } from 'react-currency-mask';
import { validationCreateAssociado } from '../../../validations/associados.schema';
import { useAssociado } from '../../../../../shared/hooks/associados/useAssociado';
import { EditDepedenteModal } from '../EditDependente/EditDependente';
import { AssociadoService } from '../../../../../shared/services/api/associados/AssociadoService';
import { CreateDependenteModal } from '../EditDependente/components/CreateDependenteModal';

type CreateAssociadoProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  getAssociados: any;
  associado: any;
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const rows: any[] = [];
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && 
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      }
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const zones = [
  'NORTE',
  'SUL',
  'LESTE',
  'OESTE',
  'CENTRO_SUL',
  'DOT',
  'SEDE',
  'OUTROS',
];
export const EditAssociadoModal: React.FC<CreateAssociadoProps> = ({
  onClose,
  associado,
  getAssociados,
  ...rest
}) => {
  const { loading, updateAssociado, dependentes, getDependentesByAssociado } =
    useAssociado({ getAssociados }, [getAssociados]);
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [createDependente, setCreateDependente] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [idDelete, setIdDelete] = React.useState<number>(0);
  const open = Boolean(anchorEl);
  const [dependente, setDependente] = React.useState({});
  const handleChanges = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    _dependente: any
  ) => {
    setDependente(_dependente);
    setIdDelete(_dependente.id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const factoryData = () => {
    return {
      name: associado?.name,
      matricula: associado?.matricula,
      email: associado?.email,
      rg: associado?.rg,
      setor: associado?.setor,
      cpf: associado?.cpf,
      zoneCity: associado?.zoneCity,
      celular: associado?.celular,
      salarioBase: associado?.salarioBase,
    };
  };

  const {
    touched,
    errors,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik<any>({
    onSubmit: async (values) => {
      console.log('values', values);
      await updateAssociado(associado?.id, values);
      onClose();
      getAssociados();
    },
    enableReinitialize: true,
    validationSchema: validationCreateAssociado,
    initialValues: {
      ...factoryData(),
    },
  });

  React.useEffect(() => {
    if (associado?.id) {
      getDependentesByAssociado(associado?.id);
    }
  }, [associado]);
  const deleteDependente = async (id: number) => {
    try {
      await AssociadoService.deleteDependenteById(id);
      await getDependentesByAssociado(associado?.id);
      handleDeleteClose();
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  return (
    <BaseModal
      loading={loading}
      onClose={onClose}
      onSubmit={handleSubmit}
      hasForm
      {...rest}
    >
      <Tabs
        value={value}
        onChange={handleChanges}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
        variant="fullWidth"
      >
        <Tab label="Dados" {...a11yProps(0)} />
        <Tab label="Dependentes" {...a11yProps(1)} />
        <Tab label="Planos" {...a11yProps(2)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <Box>
          <form onSubmit={handleSubmit}>
            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <TextField
                margin="dense"
                name="name"
                label="NOME COMPLETO"
                type="text"
                fullWidth
                variant="outlined"
                value={values.name}
                onChange={handleChange('name')}
                onBlur={handleBlur('name')}
                error={!!(errors?.name && touched?.name)}
              />
            </Stack>

            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <TextField
                margin="dense"
                name="email"
                label="EMAIL"
                type="text"
                fullWidth
                variant="outlined"
                value={values?.email}
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
                error={!!(errors?.email && touched?.email)}
              />
            </Stack>

            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <TextField
                margin="dense"
                name="zoneCity"
                label="ZONA"
                type="text"
                select
                fullWidth
                variant="outlined"
                placeholder="ZONA"
                value={values.zoneCity ?? ''}
                onChange={handleChange('zoneCity')}
                onBlur={handleBlur('zoneCity')}
                error={!!(errors.zoneCity && touched.zoneCity)}
              >
                {zones.map((zone) => 
                  <MenuItem key={zone} value={zone}>
                    {zone}
                  </MenuItem>
                )}
              </TextField>
            </Stack>

            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <TextField
                margin="dense"
                name="matricula"
                label="MATRÍCULA"
                type="text"
                fullWidth
                variant="outlined"
                value={values.matricula}
                onChange={handleChange('matricula')}
                onBlur={handleBlur('matricula')}
                error={!!(errors?.matricula && touched?.matricula)}
              />

              <TextField
                margin="dense"
                name="setor"
                label="SETOR"
                type="text"
                fullWidth
                variant="outlined"
                value={values.setor}
                onChange={handleChange('setor')}
                onBlur={handleBlur('setor')}
                error={!!(errors?.setor && touched?.setor)}
              />
            </Stack>
            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <InputMask
                value={values?.rg}
                onBlur={handleBlur('rg')}
                onChange={(value) => {
                  setFieldValue(
                    'rg',
                    value.target.value.replace(/[^0-9]+/g, '')
                  );
                }}
                mask="9999999-9"
              >
                <TextField
                  margin="dense"
                  name="rg"
                  label="RG"
                  type="text"
                  fullWidth
                  variant="outlined"
                  // value={props.values.rg}
                  // onChange={props.handleChange('rg')}
                  // onBlur={props.handleBlur('rg')}
                  error={!!(errors?.rg && touched?.rg)}
                />
              </InputMask>

              <InputMask
                value={values?.cpf}
                onBlur={() => handleBlur('cpf')}
                onChange={(value) => {
                  setFieldValue(
                    'cpf',
                    value.target.value.replace(/[^0-9]+/g, '')
                  );
                }}
                mask="999.999.999-99"
              >
                <TextField
                  margin="dense"
                  name="cpf"
                  label="CPF"
                  type="text"
                  fullWidth
                  variant="outlined"
                  // value={props.values.cpf}
                  // onChange={props.handleChange('cpf')}
                  // onBlur={props.handleBlur('cpf')}
                  error={!!(errors?.cpf && touched?.cpf)}
                />
              </InputMask>
            </Stack>
            <Stack
              display={'flex'}
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <InputMask
                value={values?.celular}
                onBlur={handleBlur('celular')}
                onChange={(value) => {
                  setFieldValue(
                    'celular',
                    value.target.value.replace(/[^0-9]+/g, '')
                  );
                }}
                mask="(99)99999-9999"
              >
                <TextField
                  margin="dense"
                  name="celular"
                  label="CELULAR"
                  type="text"
                  fullWidth
                  variant="outlined"
                  // value={props.values.celular}
                  // onChange={props.handleChange('celular')}
                  // onBlur={props.handleBlur('celular')}
                  error={!!(errors?.celular && touched?.celular)}
                />
              </InputMask>
              <CurrencyInput
                InputElement={
                  <TextField
                    name="salarioBase"
                    margin="dense"
                    fullWidth
                    onChange={handleChange('salarioBase')}
                    value={values.salarioBase}
                    onBlur={handleBlur('salarioBase')}
                    label="SALARIO BASE"
                    variant="outlined"
                    error={!!(errors?.salarioBase && touched?.salarioBase)}
                  />
                }
                value={values.salarioBase}
                onChangeValue={(event, originalValue, maskedValue) => {
                  console.log(event, originalValue, maskedValue);
                  setFieldValue('salarioBase', originalValue);
                }}
              />
            </Stack>
            <DialogActions>
              <LoadingButton
                variant="contained"
                loading={loading}
                type="submit"
              >
                Atualizar
              </LoadingButton>
            </DialogActions>
          </form>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box sx={{ height: 410 }}>
          <Grid container justifyContent="flex-end" mb={2}>
            <Button
              onClick={() => setCreateDependente(true)}
              variant="contained"
            >
              Adicionar Dependente
            </Button>
          </Grid>
          <TableContainer style={{ marginBottom: 20 }} component={Paper}>
            <Table sx={{ mb: 2 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell align="right">Idade</TableCell>
                  <TableCell align="right">Parentesco</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dependentes?.map((row: any) => 
                  <TableRow
                    key={row.nome}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.nome}
                    </TableCell>
                    <TableCell align="right">
                      {new Intl.DateTimeFormat('pt-BR').format(
                        row.dataNascimento
                      )}
                    </TableCell>
                    <TableCell align="right">{row.grauParentesco}</TableCell>
                    <TableCell align="right">
                      <Button onClick={(event) => handleClick(event, row)}>
                        <MoreVertIcon fontSize="medium" />
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <DialogActions>
          <LoadingButton
            variant="contained"
            onClick={() => {
              onClose();
            }}
          >
            Finalizar
          </LoadingButton>
        </DialogActions>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Box sx={{ height: 410 }}>
          <TableContainer sx={{ mb: 10 }} component={Paper}>
            <Table
              size="small"
              sx={{ minWidth: 350 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Plano</TableCell>
                  <TableCell align="right">Valor</TableCell>
                  <TableCell align="right">Data de entrada</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row: any) => 
                  <TableRow
                    key={row.nome}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.nome}
                    </TableCell>
                    <TableCell align="right">{row.idade}</TableCell>
                    <TableCell align="right">{row.grauParentesco}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <DialogActions>
          <LoadingButton
            variant="contained"
            onClick={() => {
              onClose();
            }}
          >
            Finalizar
          </LoadingButton>
        </DialogActions>
      </CustomTabPanel>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setOpenDelete(true)}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Deletar</ListItemText>
        </MenuItem>
      </Menu>
      <EditDepedenteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        getDependentesByAssociado={getDependentesByAssociado}
        dependente={dependente}
        associado={associado}
      />
      <CreateDependenteModal
        open={createDependente}
        onClose={() => setCreateDependente(false)}
        dependente={dependente}
        associado={associado}
        getDependentesByAssociado={getDependentesByAssociado}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Desativar Dependente?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você deseja remover este Dependente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>NÃO</Button>
          <Button onClick={() => deleteDependente(idDelete)}>SIM</Button>
        </DialogActions>
      </Dialog>
    </BaseModal>
  );
};
