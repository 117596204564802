import React from 'react';
import { Toolbar } from '../../../shared/components';
import { LayoutBase } from '../../../shared/layouts';
import { IConvenio } from '../../../shared/models/Convenio';
import mockConvenios from '../../../shared/mocks/convenios.json';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, LinearProgress, Pagination, Box, Alert, Snackbar, Button, Icon, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tabs, Tab } from '@mui/material';
import { Environment } from '../../../shared/environment';
import { useConvenios } from '../../../shared/hooks/convenios/useConvenios';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { useApplicationContext } from '../../../shared/context/ApplicationContext';
import { useAssociados } from '../../../shared/hooks/associados/useAssociados';
import { AssociadoService } from '../../../shared/services/api/associados/AssociadoService';
import { CreateAssociadoModal } from '../../associados/components/modal/Create/CreateAssociado';
import { TabPanel } from '../../../shared/components/tabs/TabPanel';
import { PlanoAssociado } from '../container/plano-associado/PlanoAssociado';
import { Planos } from '../container/planos/Planos';

interface IPagination {
  page: number,
  total: number
}
export const ListPlanos: React.FC = () => {

  const [value, setValue] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const { debounce } = useDebounce();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [idDelete, setIdDelete] = React.useState<number>(0);
  const { associados, getAssociados, loading, error, totalElements, totalPage } = useAssociados();
  const { errorAlert, changeErrorAlert } = useApplicationContext();
  const [pagination, setPagination] = React.useState<IPagination>(()=>({
    page:1,
    total:0
  }));

  React.useEffect(()=>{
    getAssociados({});
  },[]);

  const changeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const onChangeSearch = React.useCallback((value: string) =>{
    setSearch(value);
    console.log(value);
    debounce(()=>{
      getAssociados({searchParam: value});
    });

  },[search]);

  const handleClose = () => {
    setOpen(false);
  };

  const factoryConvenio = ({local, name, responsavel, telefone}: IConvenio) => {
    return { name, local, responsavel, telefone };
  };

  const rows = mockConvenios
    .map((convenio: IConvenio)=> factoryConvenio(convenio));

  const handleClickEditOpen = (row: any) => {
    console.log('');

  };

  const handleClickDeleteOpen = (id: number) => {
    setIdDelete(id);
    setOpenDelete(true);
  };

  const deleteConvenio = async (id: number) => {
    try {
      await AssociadoService.deleteById(id);
      getAssociados({});
      handleDeleteClose();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <LayoutBase
      title='Planos'
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs    indicatorColor="primary"
            textColor="primary"
            variant="fullWidth" value={value} onChange={changeTab} aria-label="basic tabs example">
            <Tab label="Plano" {...a11yProps(0)} />
            <Tab label="Plano Associado" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>

      <TabPanel value={value} index={0}>
        <Planos />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <PlanoAssociado />
      </TabPanel>

    </LayoutBase>
  );
};
