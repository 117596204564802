import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthService } from '../services/api/auth/AuthService';

type AuthContextData = {
  isAuthenticated: boolean,
  logout: () => void,
  login: (email: string, password: string) => Promise<string | void>
}
type AuthProviderProps = {
  children: React.ReactNode
}

const AuthContext = createContext({} as AuthContextData);

export const AuthProvider = ({ children }: AuthProviderProps) => {

  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    const accessToken = localStorage.getItem('asstrans:token');
    if(accessToken){
      setAccessToken(accessToken);
    }else{
      setAccessToken(undefined);
    }
  },[]);

  const isAuthenticated = useMemo(() => !!accessToken, [accessToken]);

  const login = useCallback(async (email: string, password: string)=>{
    try {
      const result = await AuthService.login(email, password);
      console.log('result', result);
      localStorage.setItem('asstrans:token', result.access_token);
      setAccessToken(result.access_token);
    }catch(error){
      console.log(error);
    }
  },[]);

  const logout = useCallback( ()=> {
    setAccessToken(undefined);
    localStorage.removeItem('asstrans:token');
  },[]);

  return(
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);