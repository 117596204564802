import React from 'react';
import { AssociadoService } from '../../services/api/associados/AssociadoService';


type UseConveniosProps = {
  page?: number,
  limit?: number,
  searchParam?: string,
}
export function useAssociados() {

  const [associados, setAssociados] = React.useState<any[]>([]);
  const [dependentes, setDependentes] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [ totalElements, setTotalElements] = React.useState(0);
  const [ totalPage, setTotalPage] = React.useState(0);
  const [ error, setError ] = React.useState<any>(null);

  const getAssociados = async ({ page = 0, limit = 10, searchParam=''}: UseConveniosProps ) => {
    try {
      setLoading(true);
      const data  = await AssociadoService.findAll(page,limit, searchParam);
      setTotalElements(data.totalElements);
      setTotalPage(data.totalPages);
      setAssociados(data.content);
    } catch (error) {
      console.log('error', error);
      setError(error);
    }finally{
      setLoading(false);
    }
  };

  const getDependentesByAssociado = async (id: string | number) => {
    try {
      setLoading(true);
      const data  = await AssociadoService.findAllDependentesByAssociado(id);
      setDependentes(data);
    } catch (error) {
      console.log('error', error);
      setError(error);
    }finally{
      setLoading(false);
    }
  };
  return { associados, dependentes, getAssociados, getDependentesByAssociado, totalElements, totalPage, loading, error };
}
