import * as yup from 'yup';

export const validationCreatePlano = yup.object().shape({
  cnpj: yup.string().required('Campo obrigatório.'),
  valor: yup.string().required('Campo obrigatório.'),
  address: yup.string().required('Campo obrigatório.'),
  nome: yup.string().required('Campo obrigatório.'),
  corporateName: yup.string().required('Campo obrigatório.'),
  tel: yup.string().required('Campo obrigatório.'),
  email: yup.string().email().required('Campo obrigatório.')
});