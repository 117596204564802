import { Api } from '../axios-config';

const deletePlanoAssociado = async (id: number): Promise<any | Error> => {
  try {
    const { data: _data } = await Api.delete(`/planos-associados/${id}`);
    return _data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao deletar o plano.');
  }
};

const processPlanoAssociado = async (data: any): Promise<any | Error> => {
  try {
    const { data: _data } = await Api.post('/planos-associados', data);
    return _data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao inserir no plano.');
  }
};

const verifyPlanoAssociado = async (idAssociado: number, idPlano: number): Promise<any | Error> => {
  try {
    const { data } = await Api.get(`/planos-associados/associado/${idAssociado}/plano/${idPlano}`);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao ao verificar plano.');
  }
};


export const PlanosAssociadoService = {
  processPlanoAssociado,
  verifyPlanoAssociado,
  deletePlanoAssociado
};