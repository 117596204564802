import { AxiosError } from 'axios';
import { useAuthContext } from '../../../../context/AuthContext';

const ERROR_TYPE = 'Network Error';
export const errorInterceptor = (error: AxiosError<any>) => {
  if(error.message === ERROR_TYPE) {
    return Promise.reject(new Error('Erro de conexão'));
  }
  if(error.response?.status >= 400 && error.response?.status < 500) {
    if(error.response.data.error === 'invalid_token'){
      localStorage.removeItem('asstrans:token');
      window.location.href = '/';
      // return Promise.reject(new Error('Token expirado'));
    }
    return Promise.reject(new Error(error.response.data.error));
  }
  if(error.response?.status >= 500) {
    return Promise.reject(new Error('Conexão com servidor falhou, Tente novamente!'));
  }
  return Promise.reject(error);
};