import { Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, LinearProgress, Pagination, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import ReactPrint from 'react-to-print';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';

import { Toolbar } from '../../../shared/components';
import { LayoutBase } from '../../../shared/layouts';
import { Environment } from '../../../shared/environment';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { useApplicationContext } from '../../../shared/context/ApplicationContext';

import { ConvenioService } from '../../../shared/services/api/convenios/ConvenioService';
import { CreateRequisicaoModal } from '../components/model/Create/CreateRequisicao';
import { useRequisicoes } from '../../../shared/hooks/requisicoes/useRequisicoes';
import { RequisicoesService } from '../../../shared/services/api/requisicoes/RequisicoesService';
import RequisicaoPDF from '../reports/requisicao-pdf';

interface IPagination {
  page: number,
  total: number
}
export const ListRequisicoes: React.FC = () => {
  const refPdf = useRef<any>();
  const [requisicao, setRequisicao] = React.useState({});
  const { errorAlert, changeErrorAlert } = useApplicationContext();
  const [open, setOpen] = React.useState(false);
  const [idCollapse, setIdCollapse] = React.useState('');
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openModalAproveReprove, setOpenModalAproveReprove] = React.useState(false);
  const [openModalQuitarParcela, setOpenModalQuitarParcela] = React.useState(false);
  const [openModalPdf, setOpenModalPdf] = React.useState(false);
  const [convenio, setConvenio] = React.useState<any>({});
  const [idRequisicao, setIdRequisicao] = React.useState<number>(0);
  const [idParcela, setIdParcela] = React.useState<number>(0);
  const [message, setMessage] = React.useState('');
  const [parcelas, setParcelas] = React.useState([]);

  const { debounce } = useDebounce();
  const [pagination, setPagination] = React.useState<IPagination>(()=>({
    page:1,
    total:0
  }));
  const { remove, requisicoes, getRequisicoes, getParcelas, loading, error, totalPage } = useRequisicoes();

  React.useEffect(()=>{
    getRequisicoes({});
  },[]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlePdfOpen = (data: any) => {
    mountRequisicao(data);
    setOpenModalPdf(true);
  };

  const handlePdfClose = () => {
    setOpenModalPdf(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickEditOpen = React.useCallback((_convenio: any) => {
    setConvenio(_convenio);
    setOpenEdit(true);
  },[convenio]);

  const handleEditClose = React.useCallback(() => {
    setOpenEdit(false);
  },[convenio]);


  const mountRequisicao = (data: any) => {
    setRequisicao(data);
  };

  const quitar = (idRequisicao: number, idParcela: number) => {
    setIdRequisicao(idRequisicao);
    setIdParcela(idParcela);
    setOpenModalQuitarParcela(true);
  };
  const approved = (id: number) => {
    setIdRequisicao(id);
    setMessage('Aprovar');
    setOpenModalAproveReprove(true);
  };
  const reproved = (id: number) => {
    setIdRequisicao(id);
    setMessage('Reprovar');
    setOpenModalAproveReprove(true);
  };

  const changeOpenCollapse = (id: any) => {
    setIdCollapse(id.id);
  };

  const handlerApprovedReprovedClose = () => {
    setOpenModalAproveReprove(false);
  };

  const handlerQuitarParcelaClose = () => {
    setOpenModalQuitarParcela(false);
  };

  const aprrovedRequisicao = async (id: number) => {
    try {
      await RequisicoesService.approved({idRequisicao: id});
      getRequisicoes({});
      setParcelas(await getParcelas(id));
      handlerApprovedReprovedClose();
    } catch (error) {
      console.log('error', error);
    }
  };

  const reprovedRequisicao = async (id: number) => {
    try {
      await RequisicoesService.reproved({idRequisicao: id});
      getRequisicoes({});
      setParcelas(await getParcelas(id));
      handlerApprovedReprovedClose();
    } catch (error) {
      console.log('error', error);
    }
  };

  const quitarParcela = async (idRequisicao: number, idParcela: number) => {
    try {
      await RequisicoesService.quitarParcela({idRequisicao, idParcela});
      getRequisicoes({});
      setParcelas(await getParcelas(idRequisicao));
      handlerQuitarParcelaClose();
    } catch (error) {
      console.log('error', error);
    }
  };



  const  RowCollapse = (id: any) => {

    const { getParcelas } = useRequisicoes();

    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
          <TableCell sx={{border: 0}}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={ async () => {
                // setOpenCollapse(!openCollapse);
                changeOpenCollapse(id);
                setParcelas( await getParcelas(Number(id.id)));
              }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </TableCell>
        </TableRow>

      </>
    );
  };
  const Row = (item: any) => {


    return (

      <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={6}>

        <Collapse in={(idCollapse === item.item.id)} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 0 }}>
            <Typography  gutterBottom component="div">
              Parcelas
            </Typography>

          </Box>
          <Table style={{marginBottom: 30}} size="small" >
            <TableHead>

            </TableHead>
            <TableBody>
              {parcelas.map((row: any, index) => 
                <>
                  <TableRow
                    // style ={{ background : '#dfe4ea', color: '#2f3542' }}
                    key={index}
                    sx={
                      { '&:last-child td, &:last-child th': { border: 0 } }
                    }
                  >
                    <TableCell align='left'  sx={
                      { border: 0 }
                    } component="th" scope='row'>
                      <TableCell  sx={
                        { border: 0, borderBottom: 0,   color: '#636e72' }
                      }>
                        {/* <Chip
                          label={row?.status}
                          variant="outlined"
                          style={{
                            color: row?.status === 'PAGO' ? '#00b894' : '#fdcb6e'
                          }}
                        /> */}
                        {row?.requisicao?.status === 'CANCELADA' ? 
                          <>
                            <Icon color='error'>close</Icon>
                          </>
                          : 
                          <>
                            {row?.status === 'PAGO' ? 
                              <Icon color='success'>check_circle</Icon>
                              : 
                              <Icon color='info'>schedule</Icon>
                            }
                          </>
                        }

                      </TableCell>
                      <TableCell  align='left'  sx={
                        { border: 0, color: '#636e72' }
                      }>
                        {row?.name}
                      </TableCell>
                      <TableCell align='left' sx={
                        { border: 0, borderBottom: 0,   color: '#636e72' }
                      }>{Number(row?.valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL'})}</TableCell>
                      <TableCell  sx={
                        { border: 0, borderBottom: 0,  color: '#636e72' }
                      }>{new Intl.DateTimeFormat('pt-BR').format(row?.dataVencimento)}</TableCell>
                    </TableCell>
                    {row?.status === 'PENDENTE' && !(row?.requisicao.status === 'CANCELADA') && 
                      <TableCell align='left' sx={{border: 0}} colSpan={3}>
                        <Button
                          variant='outlined'
                          disableElevation
                          color='success'
                          onClick={()=> quitar(row?.requisicao?.id, row?.id)}
                          disabled={row?.requisicao?.status === 'PENDENTE'}
                          sx={{
                            marginRight: 20
                          }}
                        >
                          Dar Baixa
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    );
  };

  return (
    <LayoutBase
      title='Requisições'
      toolbar={(<Toolbar
        showField
        showButton
        handleAction={handleClickOpen}
      />)}
    >
      <Box>
        {error && 
          <Box margin={1} paddingX={5}>
            <Alert variant="outlined" severity="error">
              {error.message}
            </Alert>
          </Box>
        }
      </Box>

      {/* <div ref={refPdf}>
        <p>ALO</p>
      </div> */}

      <TableContainer  sx={{ minWidth: 400, width: 'auto', m: 1 }} component={Paper}>
        <Table  size="small" aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>ID</TableCell>
              <TableCell>ASSOCIADO</TableCell>
              <TableCell>CONVÊNIO</TableCell>
              <TableCell>STATUS</TableCell>
              <TableCell>VALOR PARCELA</TableCell>
              <TableCell>VALOR TOTAL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {requisicoes.map((row: any, index) => 
              <>
                <TableRow
                  key={index}
                  sx={
                    { '&:last-child td, &:last-child th': { border: 0 } }
                  }
                >
                  <RowCollapse id={row?.id} />
                  <TableCell component="th" scope="row">{row?.id}</TableCell>
                  <TableCell>{row?.associado?.name}</TableCell>
                  <TableCell>{row?.convenio?.fantasyName}</TableCell>
                  <TableCell>
                    <Chip
                      label={row?.status}
                      variant="outlined"
                      style={{
                        color: row?.status === 'PENDENTE'
                          ? '#fdcb6e' : row?.status === 'ABERTA'
                            ? '#74b9ff' : row?.status === 'CANCELADA'
                              ? '#ff7675' : '#00b894'
                      }}
                    />

                  </TableCell>
                  <TableCell>{Number(row?.valorParcela).toLocaleString('pt-br', { style: 'currency', currency: 'BRL'})}</TableCell>
                  <TableCell>{Number(row?.valorTotal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL'})}</TableCell>
                  <TableCell>{row?.responsible}</TableCell>

                  <TableCell colSpan={1}>
                    <Button
                      title='Gerar PDF'
                      variant='outlined'
                      disableElevation
                      color='error'
                      onClick={()=> handlePdfOpen(row)}
                      sx={{
                        marginRight: 1
                      }}
                    >
                      <Icon>picture_as_pdf</Icon>
                    </Button>
                    <Button
                      variant='outlined'
                      title='Remover Requisição'
                      disableElevation
                      color='error'
                      onClick={()=> {
                        setIdRequisicao(row.id);
                        setOpenDelete(true);
                      }}
                      sx={{
                        marginRight: 1
                      }}
                    >
                      <Icon>delete</Icon>
                    </Button>
                    <Button
                      title='Aprovar Requisição'
                      variant='outlined'
                      disableElevation
                      color='success'
                      onClick={()=>approved(row.id)}
                      disabled={!(row.status === 'PENDENTE')}
                    >
                      <Icon>check</Icon>
                    </Button>
                    <Button
                      title='Reprovar Requisição'
                      variant='outlined'
                      disableElevation
                      color='error'
                      onClick={()=>reproved(row.id)}
                      disabled={!(row.status === 'PENDENTE')}
                    >
                      <Icon>close</Icon>
                    </Button>

                  </TableCell>


                </TableRow>
                <Row item={row} />
              </>
            )}
          </TableBody>
          {requisicoes.length == 0 && !loading &&
            <caption>{Environment.LIST_EMPTY}</caption>
          }
          <TableFooter>
            {loading && 
              <TableRow>
                <TableCell colSpan={5}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            }
            {requisicoes.length >0 && 
              <TableRow>
                <TableCell colSpan={5}>
                  <Pagination
                    color='primary'
                    variant='outlined'
                    page={pagination.page}
                    count={totalPage}
                    onChange={(_, page)=>{
                      // console.log(page);
                      setPagination(prev => ({
                        ...prev,
                        page
                      }));
                      getRequisicoes({ page: page-1});
                    }}
                  />
                </TableCell>
              </TableRow>
            }
          </TableFooter>
        </Table>
      </TableContainer>

      <CreateRequisicaoModal
        open={open}
        getRequisicoes={getRequisicoes}
        onClose={handleClose}
        title="Nova Requisição"
        description='Formulário de cadastro de requisição da ASSTRANS'
      />

      <RequisicaoPDF
        open={openModalPdf}
        requisicao={requisicao}
        onClose={handlePdfClose}
      />
      {/*
      <UpdateConvenioModal
        convenio={convenio}
        open={openEdit}
        getConvenios={getConvenios}
        onClose={handleEditClose}
        title="Atualizar Convênio"
        description='Formulário de atualização de convênios da ASSTRANS'
      /> */}

      <Dialog
        open={openModalAproveReprove}
        onClose={handlerApprovedReprovedClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">
          {`${message} Requisição`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Você deseja ${message.toLocaleLowerCase()} esta Requisição?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlerApprovedReprovedClose}>NÃO</Button>
          <Button onClick={()=> {
            if(message === 'Aprovar'){
              aprrovedRequisicao(idRequisicao);
            }else{
              reprovedRequisicao(idRequisicao);
            }
          }} autoFocus>
            SIM
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModalQuitarParcela}
        onClose={handlerQuitarParcelaClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Quitar Parcela'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Você deseja dar baixa na  parcela?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlerQuitarParcelaClose}>NÃO</Button>
          <Button onClick={()=> quitarParcela(idRequisicao, idParcela)} autoFocus>
            SIM
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openDelete}
        onClose={()=> setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Remoção Requisição'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Você deseja realmente remover esta requisição?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)}>NÃO</Button>
          <Button onClick={async ()=>{
            await remove(idRequisicao);
            setOpenDelete(false);
            getRequisicoes({});
          }} autoFocus>
            SIM
          </Button>
        </DialogActions>
      </Dialog>

      {errorAlert && 
        <Snackbar  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={()=>changeErrorAlert(null)} open={errorAlert.error} autoHideDuration={3000}>
          <Alert onClose={()=>changeErrorAlert(null)} severity={errorAlert.type} sx={{ width: '100%' }}>
            {errorAlert?.message}
          </Alert>
        </Snackbar>
      }


    </LayoutBase>
  );
};
