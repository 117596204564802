import React from 'react';
import { Toolbar } from '../../../shared/components';
import { LayoutBase } from '../../../shared/layouts';
import { IConvenio } from '../../../shared/models/Convenio';
import mockConvenios from '../../../shared/mocks/convenios.json';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  LinearProgress,
  Pagination,
  Box,
  Alert,
  Snackbar,
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Environment } from '../../../shared/environment';
import { useConvenios } from '../../../shared/hooks/convenios/useConvenios';
import { CreateAssociadoModal } from '../components/modal/Create/CreateAssociado';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { useApplicationContext } from '../../../shared/context/ApplicationContext';
import { useAssociados } from '../../../shared/hooks/associados/useAssociados';
import { AssociadoService } from '../../../shared/services/api/associados/AssociadoService';
import { EditAssociadoModal } from '../components/modal/Edit/EditAssociado';
import { log } from 'console';

interface IPagination {
  page: number;
  total: number;
}
export const ListAssociados: React.FC = () => {
  const [search, setSearch] = React.useState('');
  const { debounce } = useDebounce();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [associado, setAssociado] = React.useState<any>();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [idDelete, setIdDelete] = React.useState<number>(0);
  const {
    associados,
    getAssociados,
    loading,
    error,
    totalElements,
    totalPage,
  } = useAssociados();
  const { errorAlert, changeErrorAlert } = useApplicationContext();
  const [pagination, setPagination] = React.useState<IPagination>(() => ({
    page: 1,
    total: 0,
  }));

  React.useEffect(() => {
    getAssociados({});
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteClose = () => {
    //setAssociado({});
    setOpenDelete(false);
  };

  const onChangeSearch = React.useCallback(
    (value: string) => {
      setSearch(value);
      console.log(value);
      debounce(() => {
        getAssociados({ searchParam: value });
      });
    },
    [search]
  );

  const handleClose = () => {
    setAssociado({});
    setOpen(false);
  };
  const handleClickEditOpen = (_associado: any) => {
    setAssociado(_associado);
    setOpenEdit(true);

  };

  const handleEditClose = () => {
    setAssociado({});
    setOpenEdit(false);
  };

  const factoryConvenio = ({
    local,
    name,
    responsavel,
    telefone,
  }: IConvenio) => {
    return { name, local, responsavel, telefone };
  };

  const rows = mockConvenios.map((convenio: IConvenio) =>
    factoryConvenio(convenio)
  );

  const handleClickDeleteOpen = (id: number) => {
    setIdDelete(id);
    setOpenDelete(true);
  };

  const deleteConvenio = async (id: number) => {
    try {
      await AssociadoService.deleteById(id);
      getAssociados({});
      handleDeleteClose();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <LayoutBase
      title="Associados"
      toolbar={
        <Toolbar
          showField
          showButton
          value={search}
          onChange={onChangeSearch}
          handleAction={handleClickOpen}
        />
      }
    >
      <TableContainer
        sx={{ minWidth: 400, width: 'auto', m: 1 }}
        component={Paper}
      >
        <Table size="small" aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>NOME</TableCell>
              <TableCell>MATRÍCULA</TableCell>
              <TableCell>RG</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>SALÁRIO BASE</TableCell>
              <TableCell>LIMITE TOTAL</TableCell>
              <TableCell>LIMITE UTILIZADO</TableCell>
              <TableCell>ZONA</TableCell>
              <TableCell>CELULAR</TableCell>
              <TableCell>ACÕES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {associados.map((row: any, index) => 
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.matricula}</TableCell>
                <TableCell>{row.rg}</TableCell>
                <TableCell>{row.cpf}</TableCell>
                <TableCell>
                  {Number(row.salarioBase).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </TableCell>
                <TableCell>
                  {Number(row.limiteTotal).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </TableCell>
                <TableCell>
                  {Number(row.limiteUtilizado).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </TableCell>
                <TableCell>{row.zoneCity}</TableCell>
                <TableCell>{row.celular}</TableCell>

                <TableCell colSpan={1}>
                  <Button
                    variant="outlined"
                    disableElevation
                    onClick={() => handleClickEditOpen(row)}
                    sx={{
                      marginRight: 1,
                    }}
                  >
                    <Icon>mode_edit</Icon>
                  </Button>
                  <Button
                    variant="outlined"
                    disableElevation
                    color="error"
                    onClick={() => handleClickDeleteOpen(row.id)}
                  >
                    <Icon>delete</Icon>
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {associados.length == 0 && !loading && 
            <caption>{Environment.LIST_EMPTY}</caption>
          }
          <TableFooter>
            {loading && 
              <TableRow>
                <TableCell colSpan={5}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            }
            {associados.length > 0 && 
              <TableRow>
                <TableCell colSpan={5}>
                  <Pagination
                    variant="outlined"
                    color="primary"
                    page={pagination.page}
                    count={totalPage}
                    onChange={(_, page) => {
                      // console.log(page);
                      setPagination((prev) => ({
                        ...prev,
                        page,
                      }));
                      getAssociados({ page: page - 1 });
                    }}
                  />
                </TableCell>
              </TableRow>
            }
          </TableFooter>
        </Table>
      </TableContainer>

      <CreateAssociadoModal
        open={open}
        onClose={handleClose}
        getAssociados={getAssociados}
      />
      <EditAssociadoModal
        open={openEdit}
        onClose={handleEditClose}
        getAssociados={getAssociados}
        associado={associado}
        title="Atualizar Associado"
        description="Formulário de atualização de Associado da ASSTRANS"
      />

      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Desativar Associado'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você deseja remover este Associado?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>NÃO</Button>
          <Button onClick={() => deleteConvenio(idDelete)} autoFocus>
            SIM
          </Button>
        </DialogActions>
      </Dialog>
      {errorAlert && 
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={() => changeErrorAlert(null)}
          open={errorAlert.error}
          autoHideDuration={3000}
        >
          <Alert
            onClose={() => changeErrorAlert(null)}
            severity={errorAlert.type}
            sx={{ width: '100%' }}
          >
            {errorAlert?.message}
          </Alert>
        </Snackbar>
      }
    </LayoutBase>
  );
};
