import { Box, Button, Icon, Modal, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useRef } from 'react';
import ReactPrint from 'react-to-print';
import { useRequisicoes } from '../../../shared/hooks/requisicoes/useRequisicoes';

type ModalProps = {
  open: boolean;
  onClose: () => void;
};
type RequisicaoPDFProps = {
  requisicao: any;
} & ModalProps;

// eslint-disable-next-line react/display-name
const RequisicaoPDF = React.forwardRef<HTMLDivElement, RequisicaoPDFProps>(
  (props, ref) => {
    const refPdf = useRef<any>();
    const [parcelas, setParcelas] = React.useState([]);
    const { getParcelas } = useRequisicoes();

    useEffect(() => {
      if (props?.requisicao?.id) {
        mountParcelas();
      }
      // return () => {
      //   setParcelas([]);
      //   props.requisicao.id = 0;
      // };
    }, [props?.requisicao?.id]);

    const mountParcelas = async () => {
      setParcelas(await getParcelas(props?.requisicao?.id));
    };

    return (
      <Modal
        open={props.open}
        onClose={props.onClose}
        title=""
        style={{ overflow: 'scroll' }}
      >
        <Box bgcolor={'white'}>
          <ReactPrint
            content={() => {
              return refPdf.current;
            }}
            trigger={() => (
              <Button
                title="Gerar PDF"
                variant="contained"
                disableElevation
                color="error"
                onClick={() => console.log('')}
                sx={{
                  marginTop: 2,
                  marginLeft: 1,
                }}
              >
                IMPRIMIR REQUISIÇÃO
                <Icon>print</Icon>
              </Button>
            )}
          ></ReactPrint>
          <Box ref={refPdf} sx={{ padding: 1, background: '#FFF' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyItems: 'center',
                border: 1,
                padding: 1,
                paddingTop: 5,
                borderColor: '#000',
              }}
            >
              <Stack
                display={'flex'}
                alignItems="center"
                direction={{ xs: 'row', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <Box sx={{ textAlign: 'center' }} width={'75%'}>
                  <Typography
                    style={{ letterSpacing: 10 }}
                    variant="h4"
                    component="h2"
                  >
                    ASSTRANS
                  </Typography>
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant="h6" component="h2">
                      Associação dos Funcionários do MANAUSTRANS (BASE)
                    </Typography>
                    <Typography component="h2">
                      <b>End:</b> Rua Wilkens de Matos, n. 360, Bairro
                      Aparecida, CEP: 69010-420
                    </Typography>
                    <Typography component="h2">
                      <b>Fones:</b> (92) 99514-6554
                    </Typography>
                  </Box>
                </Box>
                <Box width={'25%'}>
                  <img width={175} src={'logo_pdf.png'} />
                </Box>
              </Stack>
            </Box>
            <Box sx={{ border: 1, padding: 2 }}>
              <Stack
                display={'flex'}
                alignItems="center"
                direction={{ xs: 'row', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <Box sx={{ textAlign: 'center' }} width={'50%'}>
                  <Typography variant="h6" component="h2">
                    AUTORIZAÇÃO DE COMPRAS
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }} width={'50%'}>
                  <Typography variant="h6" component="h2">
                    N° {props?.requisicao?.id}
                  </Typography>
                </Box>
              </Stack>

              <Stack
                display={'flex'}
                alignItems="center"
                direction={{ xs: 'row', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <Box sx={{ marginTop: 5, marginBottom: 5 }} width={'70%'}>
                  <Typography component="h2">
                    <b>ASSOCIADO:</b>{' '}
                    {props.requisicao?.associado?.name ?? 'Sem dados'}
                  </Typography>
                  <Typography component="h2">
                    <b>MATRÍCULA:</b>{' '}
                    {props.requisicao?.associado?.matricula ?? 'sem dados'}
                  </Typography>
                  <Typography>
                    <b>NOME DO CONVÊNIO:</b>{' '}
                    {props.requisicao?.convenio?.fantasyName ?? 'sem dados'}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'right' }} width={'30%'}>
                  <Typography component="h2">
                    <b>DATA:</b>{' '}
                    {props.requisicao?.dataRequisicao
                      ? new Intl.DateTimeFormat('pt-BR').format(
                        props.requisicao?.dataRequisicao
                      )
                      : new Intl.DateTimeFormat('pt-BR').format(new Date())}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            <Box
              sx={{
                padding: 2,
                border: 1,
                borderColor: '#000',
              }}
            >
              <Typography>
                <b>VALOR TOTAL DA COMPRA:</b>{' '}
                {Number(props.requisicao?.valorTotal).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>

              <Typography>
                <b>QTD DE PARCELAS:</b> {parcelas.length}
              </Typography>

              <Box
                display={'flex'}
                flexDirection="column"
                height={'150px'}
                flexWrap={'wrap'}
              >
                {parcelas.map((parcela, index) => (
                  <>
                    <Typography>
                      <b>{`${index + 1}° -`}</b>{' '}
                      {`${new Intl.DateTimeFormat('pt-BR').format(
                        parcela['dataVencimento']
                      )} - ${Number(parcela['valor']).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}`}
                    </Typography>
                  </>
                ))}
              </Box>

              <Box sx={{ marginTop: 7 }}>
                <Stack
                  display={'flex'}
                  alignItems="center"
                  direction={{ xs: 'row', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                  <Box textAlign={'center'} width={'50%'}>
                    ______________________________________
                    <Typography sx={{ marginTop: 1 }}>
                      PRESIDENTE DO ASSTRANS
                    </Typography>
                  </Box>

                  <Box textAlign={'center'} width={'50%'}>
                    ______________________________________
                    <Typography sx={{ marginTop: 1 }}>
                      EMITENTE DA REQUISIÇÃO
                    </Typography>
                  </Box>
                </Stack>
              </Box>

              <Box sx={{ marginTop: 7 }}>
                <Stack
                  display={'flex'}
                  alignItems="center"
                  direction={{ xs: 'row', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                  <Box textAlign={'center'} width={'50%'}>
                    ______________________________________
                    <Typography sx={{ marginTop: 1 }}>ASSOCIADO</Typography>
                  </Box>
                  <Box textAlign={'center'} width={'50%'}>
                    ______________________________________
                    <Typography sx={{ marginTop: 1 }}>
                      DEPEDENTE DO ASSOCIADO
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>

            <Box
              sx={{
                border: 1,
                borderColor: '#000',
              }}
            >
              <Stack
                display={'flex'}
                alignItems="center"
                direction={{ xs: 'row', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <Box textAlign={'center'} width={'50%'}>
                  <Typography>
                    <b>AUTORIZAÇÃO DE COMPRAS</b>
                  </Typography>
                </Box>
                <Box sx={{ paddingTop: 1 }} textAlign={'center'} width={'50%'}>
                  <Stack
                    display={'flex'}
                    alignItems="center"
                    direction={{ xs: 'row', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                    <Box textAlign={'center'} width={'50%'}>
                      <Typography>
                        <b>N°:</b>
                        {props.requisicao?.id}
                      </Typography>
                    </Box>
                    <Box textAlign={'center'} width={'50%'}>
                      <Typography>
                        <b>DATA:</b>{' '}
                        {props.requisicao?.dataRequisicao
                          ? new Intl.DateTimeFormat('pt-BR').format(
                            props.requisicao?.dataRequisicao
                          )
                          : new Intl.DateTimeFormat('pt-BR').format(new Date())}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
              <Stack
                display={'flex'}
                alignItems="center"
                direction={{ xs: 'row', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <Box sx={{ paddingLeft: 2, paddingTop: 2 }} width={'70%'}>
                  <Typography component="h2">
                    <b>ASSOCIADO:</b> {props.requisicao?.associado?.name}
                  </Typography>
                </Box>
                <Box sx={{ paddingLeft: 1, paddingTop: 2 }} width={'29%'}>
                  <Typography>
                    <b>NOME DO CONVÊNIO:</b>{' '}
                    {props.requisicao?.convenio?.fantasyName ?? 'sem dados'}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                display={'flex'}
                alignItems="center"
                direction={{ xs: 'row', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <Box sx={{ paddingLeft: 2 }} width={'20%'}>
                  <Typography>
                    <b>RG:</b> {props.requisicao?.associado?.rg ?? 'sem dados'}
                  </Typography>
                </Box>
                <Box sx={{ paddingTop: 1 }} width={'50%'}>
                  <Typography>
                    <b>MATRÍCULA:</b>{' '}
                    {props.requisicao?.associado?.matricula ?? 'sem dados'}
                  </Typography>
                </Box>
                <Box sx={{ paddingTop: 1, paddingLeft: 1 }} width={'30%'}>
                  <Typography>
                    <b>VALOR DA PARCELA:</b>{' '}
                    {Number(parcelas[0]?.valor).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Box>
              </Stack>

              <Stack
                display={'flex'}
                alignItems="center"
                direction={{ xs: 'row', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <Box sx={{ padding: 2 }} width={'50%'}>
                  <Typography component="h2">
                    <b>ASS:</b>
                  </Typography>
                </Box>
                <Box width={'20%'}>
                  <Typography component="h2">
                    <b>QTD: PARC:</b> {parcelas.length}
                  </Typography>
                </Box>
                <Box width={'30%'}>
                  <Typography component="h2">
                    <b>VALOR TOTAL:</b>{' '}
                    {Number(props.requisicao?.valorTotal).toLocaleString(
                      'pt-br',
                      { style: 'currency', currency: 'BRL' }
                    )}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }
);
export default RequisicaoPDF;
