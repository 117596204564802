import { Api } from '../axios-config';

const findReportUnificada = async (mes: number, ano: number): Promise<any | Error> => {
  try {
    const { data } = await Api.get(`/requisicoes/unificada/?mes=${mes}&ano=${ano}`);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao buscar relatório.');
  }
};

const findReportUnificadaTotal = async (mes: number, ano: number): Promise<any | Error> => {
  try {
    const { data } = await Api.get(`/requisicoes/unificada/total/?mes=${mes}&ano=${ano}`);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao buscar relatório.');
  }
};

const findReportNormal = async (mes: number, ano: number): Promise<any | Error> => {
  try {
    const { data } = await Api.get(`/requisicoes/normal/?mes=${mes}&ano=${ano}`);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao buscar relatório.');
  }
};

const findReportNormalTotal = async (mes: number, ano: number): Promise<any | Error> => {
  try {
    const { data } = await Api.get(`/requisicoes/normal/total/?mes=${mes}&ano=${ano}`);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao buscar relatório.');
  }
};

const findReportConsolidada = async (mes: number, ano: number): Promise<any | Error> => {
  console.log(mes, ano);

  try {
    const { data } = await Api.get(`/requisicoes/consolidada/requisicoes-mes-associados/?mes=${mes}&ano=${ano}`);
    return data;

  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao buscar relatório.');
  }
};

const findReportPlanosAssociados = async (): Promise<any | Error> => {

  try {
    const { data } = await Api.get('/requisicoes/consolidada/planos-associados');
    return data;

  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao buscar relatório.');
  }
};

const findReportAllAssociados = async (): Promise<any | Error> => {

  try {
    const { data } = await Api.get('/associados/all');
    return data;

  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao buscar relatório.');
  }
};

const findReportAllDependentes = async (): Promise<any | Error> => {

  try {
    const { data } = await Api.get('/requisicoes/consolidada/planos-associados-dependentes');
    return data;

  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao buscar relatório.');
  }
};

export const RelatorioService = {
  findReportUnificada,
  findReportUnificadaTotal,
  findReportNormal,
  findReportNormalTotal,
  findReportConsolidada,
  findReportPlanosAssociados,
  findReportAllAssociados,
  findReportAllDependentes
};