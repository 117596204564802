import React from 'react';
import { Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle } from '@mui/material';

type ConfirmationProps = {
  title: string,
  ask: string,
  open: boolean,
  handleClose: any,
  onHandler: any,
}
export const ConfirmationModal: React.FC<ConfirmationProps> = ({
  title,
  open,
  ask,
  handleClose,
  onHandler
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {ask}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>NÃO</Button>
        <Button onClick={async ()=>{
          await onHandler();
          handleClose();
        }}>
          SIM
        </Button>
      </DialogActions>
    </Dialog>
  );
};