import {
  Box,
  LinearProgress,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tabs,
  useTheme,
} from '@mui/material';
import React from 'react';
import { TabPanel } from '../../../shared/components/tabs/TabPanel';
import { LayoutBase } from '../../../shared/layouts';
import { RelatorioService } from '../../../shared/services/api/relatorios/RelatorioService';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ptBR } from '@mui/x-date-pickers/locales';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Environment } from '../../../shared/environment';
import { getMonths } from '../../../utils/calendar';
import { object } from 'yup';
import { ptBR as ptbr } from 'date-fns/locale/pt-BR';

export const ListRelatorioPage: React.FC = () => {
  const [range, setRange] = React.useState<Dayjs | null>(
    dayjs((new Date().getMonth() + 1).toString())
  );
  const [total, setTotal] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [month, setMonth] = React.useState(0);
  const [year, setYear] = React.useState(0);
  const [unificada, setUnificada] = React.useState<any>({});
  const [normal, setNormal] = React.useState<any>({});
  const [consolidada, setConsolidada] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();

  console.log('consolidada', consolidada);

  React.useEffect(() => {
    setLoading(true);
    setMonth(new Date().getMonth());
    setYear(new Date().getFullYear());
    buscarUnificada(new Date().getMonth(), year);
    buscarNormal(new Date().getMonth(), year);
    buscarConsolidada(new Date().getMonth(), year);
  }, []);

  React.useEffect(() => {
    setLoading(true);
    buscarUnificada(range?.month() || 1, range?.year() || 2021);
    buscarNormal(range?.month() || 1, range?.year() || 2021);
    buscarConsolidada(range?.month() || 1, range?.year() || 2021);
  }, [range]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const buscarUnificada = async (mes: number, ano: number) => {
    const obj: any = {};
    const dataUnificada: any[] = await RelatorioService.findReportUnificada(
      mes,
      ano
    );
    const dataUnificadaTotal: any[] =
      await RelatorioService.findReportUnificadaTotal(mes, ano);
    dataUnificada.forEach((data) => {
      const items: any[] = [];
      const found = dataUnificadaTotal.find(
        (i) => i.associado_id === data.associado.id
      );
      items.push(data);
      const prev = obj[found.associado_id] ? obj[found.associado_id].items : [];
      obj[found.associado_id] = {
        items: [...prev, ...items],
        total: found.total,
      };
    });
    console.log('buscarUnificada', obj);
    setLoading(false);
    setUnificada(obj);
  };

  const buscarConsolidada = async (mes: number, ano: number) => {
    const obj: any = {};
    const dataConsolidada: any[] = await RelatorioService.findReportConsolidada(
      mes,
      ano
    );
    const dataUnificadaTotal: any[] =
      await RelatorioService.findReportUnificadaTotal(mes, ano);
    const dataPlanosAssociados: any[] =
      await RelatorioService.findReportPlanosAssociados();

    const itemSimplificado: any = {};

    const dataSimplificada: any[] =
      await RelatorioService.findReportAllAssociados();

    const dataDepedentes: any[] =
      await RelatorioService.findReportAllDependentes();
    dataConsolidada.forEach((data) => {
      const items: any[] = [];
      const planos: any[] = [];
      const found = dataUnificadaTotal.find(
        (i) => i.associado_id === data.id_associado
      );
      items.push(data);
      dataPlanosAssociados.forEach((plano: any) => {
        planos.push(plano);
      });
      const prev = obj[found.associado_id] ? obj[found.associado_id].items : [];
      //const prevPlanos = obj[found.associado_id] ? obj[found.associado_id].planos : [];
      obj[found.associado_id] = {
        items: [...prev, ...items],
        planos: [
          ...planos.filter(
            (plano: any) => plano.id_associado === data.id_associado
          ),
        ],
        total_valor_planos: [
          ...planos.filter(
            (plano: any) => plano.id_associado === data.id_associado
          ),
        ].reduce((acc: any, actual: any) => acc + actual.valor_plano, 0),

        salario_associado: data.salario_associado,
        total: found.total,
      };
    });

    dataSimplificada.forEach((data) => {
      const Objectplanos: any = {};

      const foundPlanos = dataPlanosAssociados.filter(
        (i) => i.id_associado === data.id
      );

      foundPlanos.forEach((plano: any) => {
        const foundDependentes = dataDepedentes.filter(
          (i) => i.id_associado === data.id
        );

        const foundDependentesPlano = foundDependentes.filter(
          (i) => i.id_plano === plano.id_plano
        );

        Objectplanos[plano.id_associado] = {
          dependentes: foundDependentesPlano,
          total: foundDependentesPlano.reduce(
            (acc: any, actual: any) => acc + actual.valor_plano,
            0
          ),
          details: plano,
        };
      });

      itemSimplificado[data.id] = {
        name_associado: data.name,
        planos: Objectplanos,
        consolidados: obj[data.id] ? obj[data.id] : {},
      };
    });

    setLoading(false);
    setConsolidada(itemSimplificado);
  };

  const buscarNormal = async (mes: number, ano: number) => {
    const obj: any = {};
    const dataNormal: any[] = await RelatorioService.findReportNormal(mes, ano);
    const dataNormalTotal: any[] = await RelatorioService.findReportNormalTotal(
      mes,
      ano
    );
    dataNormal.forEach((data) => {
      const found = dataNormalTotal.find(
        (i) => i.convenio_id === data.convenio.id
      );
      const prev = obj[found.convenio_id] ? obj[found.convenio_id].items : [];
      obj[found.convenio_id] = {
        convenio: data.convenio,
        items: [
          ...prev,
          {
            ...data.associado,
            valorParcela: data.valorParcela,
            parcelaAtual: data.parcelaAtual,
            id: data.id,
          },
        ],
        total: found.total,
      };
    });
    setLoading(false);
    setNormal(obj);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const getTotal = () => {
    const arr = Object.keys(unificada).map((key) => unificada[key]);
    return arr.reduce((acc, actual) => acc + actual.total, 0);
  };

  return (
    <LayoutBase title="Relatórios">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="UNIFICADA" {...a11yProps(0)} />
            <Tab label="NORMAL" {...a11yProps(1)} />
            <Tab label="CONSOLIDADA" {...a11yProps(2)} />
            <Tab label="SIMPLIFICADA" {...a11yProps(3)} />
          </Tabs>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{
              margin: 3,
            }}
            views={['month', 'year']}
            format="MMM"
            value={year ? dayjs(`${year}-${month + 1}`) : null}
            onChange={(newValue) => {
              setRange(newValue);
              setYear(Number(newValue?.year()));
              setMonth(Number(newValue?.month()));
            }}
          />
        </LocalizationProvider>

        <TabPanel value={value} index={0}>
          <TableContainer
            sx={{ minWidth: 400, width: 'auto', m: 1 }}
            component={Paper}
          >
            <Table aria-label="table">
              {loading && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    RELAÇÃO DE DESCONTOS DOS FUNCIONÁRIOS - {getMonths(month)}
                    /2023
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MATRÍCULA</TableCell>
                  <TableCell align="left">ASSOCIADO</TableCell>
                  <TableCell align="left">VALORES</TableCell>
                  <TableCell align="left">PARCELA REF.</TableCell>
                  <TableCell align="left">REQUISIÇÃO</TableCell>
                  <TableCell align="left">DATA DE CRIAÇÃO</TableCell>
                  <TableCell align="left">CONVÊNIO</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(unificada).length == 0 && (
                  <caption>{Environment.LIST_EMPTY}</caption>
                )}

                {Object.keys(unificada)
                  .map((key) => unificada[key])
                  .map((obj) => (
                    <>
                      {obj.items.map((item: any, index: number) => (
                        <TableRow style={{ marginBottom: 2 }} key={index}>
                          <TableCell align="left">
                            {item.associado.matricula}
                          </TableCell>
                          <TableCell align="left">
                            {item.associado.name}
                          </TableCell>
                          <TableCell align="left">
                            {Number(item.valorParcela).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell align="left">
                            {item.parcelaAtual}
                          </TableCell>
                          <TableCell align="left">{item.id}</TableCell>
                          <TableCell align="left">
                            {new Intl.DateTimeFormat('pt-BR').format(
                              item.dataRequisicao
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {item.convenio.fantasyName}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow
                        sx={{ background: theme.palette.primary.light }}
                        key={obj.id}
                      >
                        <TableCell>
                          {' '}
                          <b>SUBTOTAL</b>
                        </TableCell>
                        <TableCell colSpan={7}>
                          <b>
                            {Number(obj.total).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </b>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
              {Object.keys(unificada).length > 0 && (
                <TableFooter>
                  <TableCell
                    sx={{ background: theme.palette.background.default }}
                  >
                    {' '}
                    <b>
                      <h3 style={{ color: '#000' }}>TOTAL</h3>
                    </b>
                  </TableCell>
                  <TableCell
                    sx={{ background: theme.palette.background.default }}
                    colSpan={7}
                  >
                    <b>
                      <h3 style={{ color: '#000' }}>
                        {Number(getTotal()).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </h3>
                    </b>
                  </TableCell>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableContainer
            sx={{ minWidth: 400, width: 'auto', m: 1 }}
            component={Paper}
          >
            <Table aria-label="table">
              {loading && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    RELAÇÃO DE DESCONTOS DOS FUNCIONÁRIOS - {getMonths(month)}
                    /2023
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell>Convenio</TableCell>
                  <TableCell align="left">CNPJ</TableCell>
                  <TableCell align="left">EMAIL</TableCell>
                  <TableCell align="left">RESPONSÁVEL</TableCell>
                  <TableCell align="left">CONTATO</TableCell>
                  <TableCell align="left">ENDEREÇO</TableCell>
                </TableRow> */}
              </TableHead>
              <TableBody>
                {Object.keys(normal).length == 0 && (
                  <caption>{Environment.LIST_EMPTY}</caption>
                )}

                {Object.keys(normal).map((key) => (
                  <>
                    <TableCell>
                      <b>DADOS CONVÊNIO</b>
                    </TableCell>
                    <TableRow style={{ marginBottom: 2 }} key={key}>
                      <TableCell align="left">
                        <b>NOME:</b> {normal[key].convenio.fantasyName}
                      </TableCell>
                      <TableCell align="left">
                        <b>CNPJ:</b> {normal[key].convenio.cnpj}
                      </TableCell>
                      <TableCell align="left">
                        <b>EMAIL:</b> {normal[key].convenio.email}
                      </TableCell>
                      <TableCell align="left">
                        <b>TEL:</b> {normal[key].convenio.tel}
                      </TableCell>
                      <TableCell align="left">
                        <b>END:</b> {normal[key].convenio.address}
                      </TableCell>
                    </TableRow>

                    {/* <TableRow key={1}>
                      <TableCell   colSpan={1}> <b>SUBTOTAL</b></TableCell>
                      <TableCell   align="right">
                        <b>{Number(obj.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL'})}</b>
                      </TableCell>
                    </TableRow> */}
                    <TableCell>
                      <b>DADOS ASSOCIADO</b>
                    </TableCell>
                    {normal[key].items.map((obj: any, index: number) => (
                      <TableRow
                        sx={{ background: theme.palette.primary.light }}
                        key={index}
                      >
                        <TableCell>
                          <b>NOME:</b> {obj.name}
                        </TableCell>
                        <TableCell>
                          <b>MATRÍCULA: </b>
                          {obj.matricula}
                        </TableCell>
                        <TableCell>
                          <b>VALOR:</b>{' '}
                          {Number(obj.valorParcela).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </TableCell>
                        <TableCell>
                          <b>REF:</b> {obj.parcelaAtual}
                        </TableCell>
                        <TableCell>
                          <b>REQ:</b> {obj.id}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow
                      sx={{ background: theme.palette.background.default }}
                    >
                      <TableCell colSpan={5}>
                        <b>
                          TOTAL:{' '}
                          {Number(normal[key].total).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}{' '}
                        </b>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
              {/* {Object.keys(unificada).length > 0 && (
                <TableFooter>
                  <TableCell colSpan={1}> <b><h3 style={{color: '#000'}}>TOTAL</h3></b></TableCell>
                  <TableCell   align="right"><b><h3 style={{color: '#000'}}>{Number(getTotal()).toLocaleString('pt-br', { style: 'currency', currency: 'BRL'})}</h3></b></TableCell>
                </TableFooter>
              )} */}
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TableContainer
            sx={{ minWidth: 400, width: 'auto', m: 1 }}
            component={Paper}
          >
            <Table aria-label="table">
              {loading && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    RELAÇÃO DE DESCONTOS DOS FUNCIONÁRIOS - {getMonths(month)}
                    /2023
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(consolidada).length == 0 && (
                  <caption>{Environment.LIST_EMPTY}</caption>
                )}
                {Object.keys(consolidada)
                  .map((key) => consolidada[key])
                  .map((obj) => (
                    <>
                      <TableRow style={{ marginBottom: 2 }}>
                        <TableCell colSpan={4} align="left">
                          <b>ASSOCIADO: </b>
                          {obj.name_associado}
                        </TableCell>

                        {obj?.consolidados?.items?.map(
                          (item: any, index: number) => (
                            <TableRow style={{ marginBottom: 2 }} key={index}>
                              <TableCell colSpan={2} align="left">
                                <b>Valor da Parcela: </b>
                                {Number(item.valor_parcela).toLocaleString(
                                  'pt-br',
                                  {
                                    style: 'currency',
                                    currency: 'BRL',
                                  }
                                )}
                              </TableCell>
                              <TableCell colSpan={2} align="left">
                                <b>CONVÊNIO: </b>
                                {item.nome_convenio}
                              </TableCell>
                              <TableCell colSpan={2} align="left">
                                <b>REQUISIÇÃO: </b>
                                {item.id_requisicao}
                              </TableCell>
                            </TableRow>
                          )
                        )}

                        {obj?.consolidados?.planos?.map(
                          (item: any, index: number) => (
                            <TableRow
                              sx={{
                                background: theme.palette.primary.light,
                              }}
                              key={index}
                            >
                              <TableCell colSpan={4} align="left">
                                <b>Plano: </b> {item.nome_plano}
                              </TableCell>
                              <TableCell colSpan={4} align="left">
                                <b>Valor: </b>

                                {Number(item.valor_plano).toLocaleString(
                                  'pt-br',
                                  {
                                    style: 'currency',
                                    currency: 'BRL',
                                  }
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}

                        <TableRow style={{ marginBottom: 2 }}>
                          <TableCell colSpan={2} align="left">
                            <b>Salario Base: </b>
                            {Number(
                              obj.consolidados.salario_associado
                            ).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell colSpan={2} align="left">
                            <b>Taxa Asstrans: </b>
                            {Number(
                              obj.consolidados.salario_associado * 0.01
                            ).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell colSpan={2} align="left">
                            <b>Valor total dos Planos: </b>
                            {Number(
                              obj.consolidados.total_valor_planos
                            ).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                        </TableRow>

                        <TableRow
                          sx={{
                            background: theme.palette.background.default,
                          }}
                        >
                          <TableCell colSpan={8} align="left">
                            <b>Total de descontos: </b>

                            <h3>
                              {Number(
                                obj.consolidados.salario_associado -
                                  (obj.consolidados.salario_associado -
                                    obj.consolidados.salario_associado * 0.01 -
                                    obj.consolidados.total -
                                    obj.consolidados.total_valor_planos) +
                                  Number(
                                    Object.entries(obj.planos).reduce(
                                      (acumulador, [chave, valor]) => {
                                        return (
                                          acumulador +
                                          Number(
                                            (valor as { total: number }).total
                                          )
                                        );
                                      },
                                      0
                                    )
                                  )
                              ).toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </h3>
                          </TableCell>
                        </TableRow>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={3}>
          Em desenvolvimento
        </TabPanel>
      </Box>
    </LayoutBase>
  );
};
