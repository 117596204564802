import React from 'react';
import { DialogActions, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { BaseModal } from '../../../../../../../shared/components/modal/BaseModal';
import { validationCreatePlano } from '../../../validations/planos.schema';
import { LoadingButton } from '@mui/lab';
import { usePlanos } from '../../../../../../../shared/hooks/planos/usePlanos';
import InputMask from 'react-input-mask';
import { CurrencyInput } from 'react-currency-mask';

type EditarPlanosProps = {
  plano: any,
  open: boolean,
  onClose: () => void,
  title: string,
  description?: string,
  getPlanos: any
}
export const EditarPlanoModal: React.FC<EditarPlanosProps> = ({
  onClose,
  plano,
  getPlanos,
  ...rest
}) => {

  const { loading, updatePlano } = usePlanos();

  const factoryData = () => {
    return {
      cnpj: plano?.cnpj,
      valor: plano?.valor,
      address: plano?.address,
      nome: plano?.nome,
      corporateName: plano?.corporateName,
      tel: plano?.tel,
      email: plano?.email
    };
  };

  const {
    touched,
    errors,
    setFieldValue,
    resetForm,
    values,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik<any>({
    onSubmit: async (values) =>{
      await updatePlano(plano?.id, values);
      getPlanos();
      resetForm();
      onClose();
    },
    enableReinitialize: true,
    validationSchema: validationCreatePlano,
    initialValues: {
      ...factoryData()
    }
  });

  return (
    <BaseModal
      loading={loading}
      onClose={onClose}
      onSubmit={handleSubmit}
      hasForm
      {...rest}
    >
      <form onSubmit={handleSubmit}>
        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >

          <InputMask
            value={values?.cnpj}
            //onChange={handleChange('cnpj')}
            onChange={(value) =>{
              // console.log('v', value.target.value.replace(/[^0-9]+/g, ''));
              setFieldValue('cnpj', value.target.value.replace(/[^0-9]+/g, ''));
            }}
            onBlur={()=>handleBlur('cnpj')}
            mask="99.999.999/9999-99"
          >
            <TextField
              margin="dense"
              name="cnpj"
              label="CNPJ"
              type="text"
              fullWidth
              variant="outlined"
              // value={values.cnpj}
              // onChange={handleChange('cnpj')}
              // onBlur={handleBlur('cnpj')}
              error={!!(errors?.cnpj && touched?.cnpj)}
            />
          </InputMask>

          <CurrencyInput
            InputElement={
              <TextField
                name="valor"
                margin="dense"
                onChange={handleChange('valor')}
                value={values.valor}
                onBlur={handleBlur('valor')}
                label="VALOR"
                variant="outlined"
                error={!!(errors?.valor && touched?.valor)}
              />
            }
            value={values.valor}
            onChangeValue={(event, originalValue, maskedValue) => {
              console.log(event, originalValue, maskedValue);
              setFieldValue('valor', originalValue);
            }}
          />

        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <TextField
            margin="dense"
            name="address"
            label="ENDEREÇO"
            type="text"
            fullWidth
            variant="outlined"
            value={values.address}
            onChange={handleChange('address')}
            onBlur={handleBlur('address')}
            error={!!(errors?.address && touched?.address)}
          />
        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >

          <TextField
            margin="dense"
            name="nome"
            label="NOME FANTASIA"
            type="text"
            fullWidth
            variant="outlined"
            value={values.nome}
            onChange={handleChange('nome')}
            onBlur={handleBlur('nome')}
            error={!!(errors?.nome && touched?.nome)}
          />

          <TextField
            margin="dense"
            name="corporateName"
            label="RAZÃO SOCIAL"
            type="text"
            fullWidth
            variant="outlined"
            value={values.corporateName}
            onChange={handleChange('corporateName')}
            onBlur={handleBlur('corporateName')}
            error={!!(errors?.corporateName && touched?.corporateName)}
          />
        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <InputMask
            value={values?.tel}
            //onChange={handleChange('tel')}
            onChange={(value) => {
              setFieldValue('tel', value.target.value.replace(/[^0-9]+/g,''));
            }}
            onBlur={()=>handleBlur('tel')}
            mask="(99)99999-9999"
          >
            <TextField
              name="tel"
              margin="dense"
              label="TELEFONE"
              type="text"
              fullWidth
              variant="outlined"
              error={!!(errors?.tel && touched?.tel)}
            />
          </InputMask>

        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >

          <TextField
            margin="dense"
            name="fax"
            label="Email"
            type="text"
            fullWidth
            variant="outlined"
            value={values.email}
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            error={!!(errors?.email && touched?.email)}
          />


        </Stack>

        <DialogActions>
          <LoadingButton
            variant='contained'
            loading={loading}
            type='submit'>Atualizar
          </LoadingButton>
        </DialogActions>
      </form>
    </BaseModal>
  );
};