import { Api } from '../axios-config';

const save = async (data: any): Promise<any | Error> => {
  try {
    const { data: _data } = await Api.post('/planos', data);
    return _data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao savar plano.');
  }
};

const remove = async (id: number): Promise<any | Error> => {
  try {
    await Api.delete(`/planos/${id}`);
  } catch (error: any) {
    if (error.message === 'Erro de conexão') {
      throw new Error((error as { message: string }).message || 'Erro ao savar plano.');
    }
    throw new Error((error as { message: string }).message ?? 'Erro ao savar plano.');
  }
};

const update = async (id: number, data: any): Promise<any | Error> => {
  try {
    const { data: _data } = await Api.put(`/planos/${id}`, data);
    return _data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao atualizar plano.');
  }
};

const findAllSearch = async (page: number, limit: number, searchParam: string): Promise<any | Error> => {
  try {
    const relative = `/planos/search?param=${searchParam}&page=${page}&limit=${limit}`;
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao listar planos.');
  }
};

const findAll = async (): Promise<any | Error> => {
  try {
    const { data } = await Api.get('/planos');
    return data;
  } catch (error: any) {
    if (error.message === 'Erro de conexão') {
      throw new Error((error as { message: string }).message || 'Erro ao listar planos.');
    }
    throw new Error((error as { message: string }).message ?? 'Erro ao listar planos.');
  }
};

export const PlanosService = {
  findAllSearch,
  findAll,
  save,
  update,
  remove
};