import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Toolbar } from '../../shared/components';
import { LayoutBase } from '../../shared/layouts';

export const Perfil: React.FC = () => {
  const [disabledMyData, setDisabledMyData] = React.useState(false);
  const [disabledSecurity, setDisabledSecurity] = React.useState(false);
  return (
    <LayoutBase title="Perfil">
      <Grid container spacing={4} ml={5}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h4">Dados Pessoais</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => setDisabledMyData(e.target.checked)}
                    />
                  }
                  label="Editar"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              style={
                !disabledMyData
                  ? {
                    pointerEvents: 'none',
                  }
                  : {}
              }
            >
              <Grid item>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: '600',
                        fontFamily: 'inherit',
                        fontSize: '15',
                      }}
                    >
                      Meus Dados
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      margin="dense"
                      name="name"
                      label="Nome"
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                    <TextField
                      margin="dense"
                      name="email"
                      label="E-mail"
                      type="email"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} textAlign={'end'}>
              <Button
                type="submit"
                variant="contained"
                style={
                  !disabledMyData
                    ? {
                      pointerEvents: 'none',
                      backgroundColor: '#ccc',
                    }
                    : {}
                }
              >
                Atualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Segurança</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => setDisabledSecurity(e.target.checked)}
                    />
                  }
                  label="Editar"
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              style={
                !disabledSecurity
                  ? {
                    pointerEvents: 'none',
                  }
                  : {}
              }
            >
              <Grid item>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: '600',
                        fontFamily: 'inherit',
                        fontSize: '15',
                      }}
                    >
                      Trocar Senha
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      margin="dense"
                      name="password"
                      label="Senha Atual"
                      type="password"
                      fullWidth
                      variant="outlined"
                    />
                    <TextField
                      margin="dense"
                      name="new_password"
                      label="Nova Senha"
                      type="password"
                      fullWidth
                      variant="outlined"
                    />
                    <TextField
                      margin="dense"
                      name="confirm_new_password"
                      label="Confirmar Nova Senha"
                      type="password"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} textAlign={'end'}>
              <Button
                type="submit"
                variant="contained"
                style={
                  !disabledSecurity
                    ? {
                      pointerEvents: 'none',
                      backgroundColor: '#ccc',
                    }
                    : {}
                }
              >
                Atualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LayoutBase>
  );
};
