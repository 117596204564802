import React from 'react';
import { useApplicationContext } from '../../context/ApplicationContext';
import { PlanosAssociadoDependenteService } from '../../services/api/plano-associado-dependente/PlanoAssociadoDependenteService';
import { PlanosAssociadoService } from '../../services/api/plano-associado/PlanoAssociadoService';

export function usePlanosAssociados() {
  const [planoAssociado, setPlanoAssociado] = React.useState({} as any);
  const [planoAssociadoDependente, setPlanoAssociadoDependente] = React.useState({} as any);
  const [loading, setLoading] = React.useState(false);
  const { changeErrorAlert } = useApplicationContext();

  const save = async (data: any) => {
    try {
      setLoading(true);
      const result = await PlanosAssociadoService.processPlanoAssociado(data);
      setPlanoAssociado(result);
      changeErrorAlert(
        {
          error: true,
          message: 'Cadastro efetuado com sucesso!',
          type: 'success'
        });
    } catch (err: any) {
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };

  const saveDependente = async (data: any) => {
    try {
      setLoading(true);
      const result = await PlanosAssociadoDependenteService.processPlanoAssociadoDependente(data);
      setPlanoAssociadoDependente(result);
      changeErrorAlert(
        {
          error: true,
          message: 'Cadastro efetuado com sucesso!',
          type: 'success'
        });
    } catch (err: any) {
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };

  const remove = async (id: number) => {
    try {
      setLoading(true);
      await PlanosAssociadoService.deletePlanoAssociado(id);
      setPlanoAssociado(null);
      setPlanoAssociadoDependente(null);
      changeErrorAlert(
        {
          error: true,
          message: 'Plano Associado removido com sucesso!',
          type: 'success'
        });
    } catch (err: any) {
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };

  const removeDependente = async (id: number) => {
    try {
      setLoading(true);
      await PlanosAssociadoDependenteService.deletePlanoAssociadoDependente(id);
      setPlanoAssociadoDependente(null);
      changeErrorAlert(
        {
          error: true,
          message: 'Plano Associado Dependente removido com sucesso!',
          type: 'success'
        });
    } catch (err: any) {
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };

  const verifyPlanoAssociado = async (idAssociado: number, idPlano: number) => {
    try {
      setLoading(true);
      const result = await PlanosAssociadoService.verifyPlanoAssociado(idAssociado, idPlano);
      setPlanoAssociado(result);
    } catch (err: any) {
      console.log(err);
    }finally{
      setLoading(false);
    }
  };

  const verifyPlanoAssociadoDependente = async (idPlanoAssociado: number, idDependente: number) => {
    try {
      setLoading(true);
      const result = await PlanosAssociadoDependenteService.verifyPlanoAssociadoDependente(idPlanoAssociado, idDependente);
      setPlanoAssociadoDependente(result);
    } catch (err: any) {
      console.log(err);
    }finally{
      setLoading(false);
    }
  };
  return {
    planoAssociado,
    planoAssociadoDependente,
    save,
    saveDependente,
    remove,
    removeDependente,
    loading,
    verifyPlanoAssociado,
    verifyPlanoAssociadoDependente
  };
}