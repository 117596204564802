import { TextField, Typography } from '@mui/material';
import { Box, Button, Card, CardActions, CardContent } from '@mui/material';
import React, { useState } from 'react';
import { useAuthContext } from '../../shared/context/AuthContext';
import * as yup from 'yup';

type LoginPageProps = {
  children: React.ReactNode
}

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required()
});

export const LoginPage = ({ children }: LoginPageProps) => {

  const { isAuthenticated, login } = useAuthContext();
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    loginSchema.validate({email, password}, {abortEarly: false})
      .then(async ({ email, password}) => {
        await login(email, password);
      })
      .catch((errors: yup.ValidationError)=>{
        errors.inner.forEach((error)=>{
          if(error.path === 'email'){
            setEmailError(error.message);
          }else if(error.path === 'password'){
            setPasswordError(error.message);
          }
        });
      }).finally(()=>{
        setLoading(false);
      });
  };

  if(isAuthenticated) return (
    <>
      {children}
    </>
  );
  return (
    <Box
      width={'100vw'}
      height={'100vh'}
      display='flex'
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Card>
        <CardContent>
          <Box
            width={350}
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            sx={{ paddingBottom: 5 }}
            textAlign="center"
          >
            <Box>
              <img width={100} src={'./logo_pdf.png'} />
            </Box>

            <Typography variant='h6'>
             ACESSO ASSTRANS
            </Typography>
            <TextField
              disabled={loading}
              fullWidth
              label="Email"
              type={'email'}
              value={email}
              error={!!emailError}
              onKeyDown={()=> setEmailError('')}
              onChange={e => setEmail(e.target.value)}
            />

            <TextField
              disabled={loading}
              fullWidth
              label="Senha"
              type={'password'}
              value={password}
              error={!!passwordError}
              onKeyDown={()=> setPasswordError('')}
              onChange={e => setPassword(e.target.value)}
            />

          </Box>
        </CardContent>
        <CardActions>
          <Box
            width={'100%'}
            display='flex'
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Button disabled={loading} variant='contained' onClick={onSubmit} >
              Entrar
            </Button>

          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

