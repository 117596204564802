import { Api } from '../axios-config';

const deletePlanoAssociadoDependente = async (id: number): Promise<any | Error> => {
  try {
    await Api.delete(`/planos-associados-dependentes/${id}`);
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao deletar o plano.');
  }
};

const processPlanoAssociadoDependente = async (data: any): Promise<any | Error> => {
  try {
    const { data: _data } = await Api.post('/planos-associados-dependentes', data);
    return _data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao inserir no plano.');
  }
};

const verifyPlanoAssociadoDependente = async (idPlanoAssociado: number, idDependente: number): Promise<any | Error> => {
  try {
    const { data } = await Api.get(`/planos-associados-dependentes/plano-associado/${idPlanoAssociado}/dependente/${idDependente}`);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao ao verificar plano.');
  }
};

export const PlanosAssociadoDependenteService = {
  processPlanoAssociadoDependente,
  verifyPlanoAssociadoDependente,
  deletePlanoAssociadoDependente
};