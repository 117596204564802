import { useFormik } from 'formik';
import React from 'react';
import { validationCreateDependente } from '../../../pages/associados/validations/associados.schema';
import { useApplicationContext } from '../../context/ApplicationContext';
import { AssociadoService } from '../../services/api/associados/AssociadoService';

export function useDependente() {
  const [loading, setLoading] = React.useState(false);
  const { changeErrorAlert } = useApplicationContext();

  const factoryData = () => {
    return {
      idAssociado: '',
      nome: '',
      dataNascimento: '',
      grauParentesco: '',
    };
  };

  const {
    touched,
    errors,
    resetForm,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik<any>({
    onSubmit: async (values) => {
      saveDependente(values);
      resetForm();
    },
    validationSchema: validationCreateDependente,
    initialValues: {
      ...factoryData(),
    },
  });

  const saveDependente = async (data: any) => {
    setLoading(true);
    try {
      await AssociadoService.createDependente(data);
      changeErrorAlert({
        error: true,
        message: 'Cadastro efetuado com sucesso!',
        type: 'success',
      });
    } catch (err: any) {
      changeErrorAlert({
        error: true,
        message: err.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateDependente = async (id: any, values: any) => {
    setLoading(true);
    try {
      await AssociadoService.updateDependenteById(id, values);

      changeErrorAlert({
        error: true,
        message: 'Dependente atualizado com sucesso!',
        type: 'success',
      });
    } catch (err: any) {
      console.log(err?.response);
      changeErrorAlert({
        error: true,
        message: err.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    saveDependente,
    updateDependente,
    props: {
      touched,
      errors,
      resetForm,
      setFieldValue,
      values,
      handleChange,
      handleBlur,
      handleSubmit,
    },
    loading,
  };
}
