import React from 'react';
import { useApplicationContext } from '../../context/ApplicationContext';
import { RequisicoesService } from '../../services/api/requisicoes/RequisicoesService';

type RequisicoesProps = {
  page?: number,
  limit?: number,
  params?: string | null,
}
export function useRequisicoes() {

  const [requisicoes, setRequisicoes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [ totalElements, setTotalElements] = React.useState(0);
  const [ totalPage, setTotalPage] = React.useState(0);
  const [ error, setError ] = React.useState<any>(null);
  const { changeErrorAlert } = useApplicationContext();


  const getRequisicoes = async ({page = 0, limit = 10, params = null }: RequisicoesProps) => {
    try{
      setLoading(true);
      const response = await RequisicoesService.findAll(page, limit, params);
      setTotalElements(response.totalElements);
      setTotalPage(response.totalPages);
      setRequisicoes(response.content);
    }catch(e){
      console.log(e);
      setError(e);
    }finally{
      setLoading(false);
    }
  };

  const getParcelas = async (id: number) => {
    try{
      setLoading(true);
      const response = await RequisicoesService.findParcelasByRequisicao(id);
      return response;
    }catch(e){
      console.log(e);
      setError(e);
    }finally{
      setLoading(false);
    }
  };

  const remove = async (id: number) => {
    try{
      setLoading(true);
      await RequisicoesService.remove(id);
      changeErrorAlert(
        {
          error: true,
          message: 'Remoção efetuada com sucesso!',
          type: 'success'
        });
    }catch(err: any){
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };

  return { remove, requisicoes, getRequisicoes, getParcelas, error, totalElements, totalPage, loading };
}